<template>
  <div class="consumer-bar-chart">
    <ApexChart
      width="100%"
      height="600"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "consumer-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#00E2F4", "#F2ADFF"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 8,
            borderRadiusApplication: "end",
            barHeight: 20,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
        },
        dataLabels: {
          textAnchor: "start",
          offsetX: 36,
          formatter: (value) => value.toLocaleString("id-ID") + " Jiwa",
          style: {
            fontSize: 11,
            fontWeight: "normal",
            color: "#ffffff20",
          },
        },
        fill: {
          colors: ["#00E2F4", "#F2ADFF"],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 4,
          colors: ["transparent"],
        },
        xaxis: {
          tickAmount: 3,
          categories: [
            ["Pre Boomer", "<1945"],
            ["Boomer", "1946 - 1964"],
            ["Generasi X", "1965 - 1980"],
            ["Milenial", "1981 - 1996"],
            ["Generasi Z", "1997 - 2012"],
            ["Post Generasi Z", "2013 dan seterusnya"],
          ],
          labels: {
            formatter: (value) => value.toLocaleString("id-ID"),
            style: {
              colors: ["#FFFFFF"],
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            minWidth: 170,
            maxWidth: 170,
            offsetY: 3,
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: ["#FFFFFF"],
              cssClass: "consumer-bar-chart-label",
            },
          },
        },
      },
      series: [
        {
          name: "Laki - laki",
          data: [2007532, 16078115, 28333040, 35394641, 36791764, 18056807],
        },
        {
          name: "Perempuan",
          data: [2616968, 16414860, 28224259, 34305331, 34717318, 17263282],
        },
      ],
    };
  },
};
</script>

<style>
.consumer-bar-chart .consumer-bar-chart-label tspan + tspan {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
}

.consumer-bar-chart .apexcharts-data-labels {
  opacity: 0.8;
}
</style>
