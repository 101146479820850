<template>
  <ApexChart
    height="240"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "coffee-order-first-chart",
  data: function () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 12,
            borderRadiusApplication: "end",
            barHeight: 26,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          formatter: (value) => `${value}%`,
        },
        fill: {
          colors: [
            ({ value, seriesIndex, w, dataPointIndex }) =>
              ["#06E3F4", "#6AF4FF", "#A8F9FF", "#A8F9FF"][dataPointIndex],
          ],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        labels: {
          style: {
            colors: ["#FFFFFF"],
          },
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          categories: ["Sumatera", "Bali", "Jawa Barat", "Flores"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            minWidth: 120,
            maxWidth: 120,
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: ["#FFFFFF"],
            },
          },
        },
      },
      series: [
        {
          data: [33.5, 31.7, 22.5, 12.3],
        },
      ],
    };
  },
};
</script>
