<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>Aplikasi Pesan Makanan Paling Favorit</h2>
    </div>
    <div class="analysis">
      <div data-aos="fade-right" data-aos-duration="1800" class="apex">
        <p>
          Praktis, hemat waktu dan tenaga menjadi alasan aplikasi pesan makanan
          begitu populer di Indonesia. Dari tiga aplikasi pesanan makanan,
          <strong>GoFood menempati posisi pertama</strong> sebagai aplikasi
          pesan makanan yang paling sering digunakan.
        </p>
        <FavoriteFoodOrderPieChart
          :gofood="48.0"
          :grabfood="28.0"
          :shopeefood="24.0"
        />
      </div>
      <div data-aos="fade-left" data-aos-duration="1800" class="apex">
        <p>
          Jika dikategorikan dari usia, <strong>perempuan</strong> lebih
          mendominasi aplikasi <strong>ShopeeFood</strong>, sedangkan
          <strong>pria</strong> lebih mendominasi aplikasi
          <strong>GoFood.</strong>
        </p>
        <FavoriteFoodOrderBarChart />
      </div>
    </div>
    <p class="foodorder-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import FavoriteFoodOrderPieChart from "./FavoriteFoodOrderPieChart.vue";
import FavoriteFoodOrderBarChart from "./FavoriteFoodOrderBarChart.vue";

export default {
  name: "method-payment",
  components: {
    FavoriteFoodOrderPieChart,
    FavoriteFoodOrderBarChart,
  },
};
</script>

<style scoped>
.section-container {
  background-image: url("../../assets/annual-report-2024/section27-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 1080px;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .header {
  color: white;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1908px;
  margin: auto;
  gap: 2rem;
}
.section-container .analysis .apex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .analysis .apex p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 790px;
  color: white;
}

.foodorder-photosource {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  top: -25px;
  left: 18px;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 900px;
  }
  .section-container .analysis .apex p {
    font-size: 1rem;
    max-width: 500px;
  }
}
</style>
