<template>
  <div class="generasiz-piechart">
    <ApexChart
      type="pie"
      width="500px"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "generasiz-piechart",
  data: function () {
    return {
      chartOptions: {
        labels: ["Coffee Shop", "Restaurant"],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -26,
            },
            colors: ["#fff"],
          },
        },
        dataLabels: {
          formatter: function (_, opts) {
            return opts.w.config.labels[opts.seriesIndex];
          },
          offsetX: 50,
          offsetY: 60,
          style: {
            fontWeight: "400",
            colors: ["#2068E7", "#00E2F4"],
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          fontSize: "14px",
          inverseOrder: true,
          offsetY: 40,
          markers: {
            width: 18,
            height: 18,
            radius: 9,
            offsetY: 5,
            offsetX: -8,
            fillColors: ["#00E2F4", "#4B00BF"],
          },
          itemMargin: {
            vertical: 23,
          },
          labels: {
            colors: ["#ffffff", "#ffffff"],
          },
          formatter: function (label, opts) {
            const percentage = opts.w.config.series[opts.seriesIndex];
            return `<div class="piechart-legend-label">${percentage}%
              <div>${label}</div>
            </div>
            `;
          },
        },
        fill: {
          colors: ["#00E2F4", "#4B00BF"],
        },
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          text: "Generasi Z",
          offsetX: 120,
          offsetY: -5,
          style: {
            fontSize: "24px",
            fontWeight: "bold",
            color: "#ffffff",
          },
        },
      },
      series: [75.24, 24.58],
    };
  },
};
</script>

<style>

.generasiz-piechart .apexcharts-legend-series {
  display: flex;
}

.generasiz-piechart .piechart-legend-label {
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
}

.generasiz-piechart .piechart-legend-label > div {
  font-size: 13px;
  font-weight: normal;
  margin-top: 3px;
}

.generasiz-piechart text {
  text-align: center;
}
</style>
