<template>
  <section class="section-container">
    <div class="left-container">
      <div data-aos="fade-down" data-aos-duration="1500" class="text-header">
        <h1>Penyedia Layanan Seluler yang Sering Digunakan</h1>
        <p>
          Pengguna kartu prabayar lebih tinggi dikarenakan lebih praktis tanpa
          perlu membayar tagihan setiap bulan dan
          <a href="https://www.antaranews.com/berita/2187938/alasan-orang-indonesia-gemar-gunakan-layanan-prabayar">dapat menyesuaikan dengan pengeluaran<sup>13</sup>.</a>
        </p>
      </div>
      <div data-aos="fade-right" data-aos-duration="1800" class="percentage">
        <div class="circle">
          <h2>56.96%</h2>
          <p>Prabayar</p>
        </div>
        <div class="circle">
          <h2>43.04%</h2>
          <p>Pascabayar</p>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div data-aos="fade-left" data-aos-duration="1800" class="apex">
        <PostPaidProviderPieChart
          :indosat="24.1"
          :telkomsel="50.4"
          :xl="21.5"
          :smartfren="3.4"
        />
      </div>
      <p class="postpaid-photosource">Photo Source: Freepik</p>
    </div>
  </section>
</template>

<script>
import PostPaidProviderPieChart from "./PostPaidProviderPieChart.vue";
export default {
  name: "post-paid-provider",
  components: {
    PostPaidProviderPieChart,
  },
};
</script>

<style scoped>
.section-container {
  width: 100%;
  height: 1080px;
  display: flex;
  overflow: hidden;
}

.section-container .left-container {
  background-color: #6676ed;
  height: 100%;
  max-width: 35%;
  padding: 0rem 3rem;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 5rem;
  padding-top: 9.688rem;
}
.section-container .left-container .text-header {
  display: flex;
  flex-direction: column;
  gap: 1.063rem;
}
.section-container .left-container .text-header h1 {
  font-family: "RMConnect";
  font-size: 2.25rem;
  font-weight: 500;
}
.section-container .left-container .text-header p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
}
.section-container .left-container .percentage {
  display: flex;
  width: 100%;
  position: relative;
}
.section-container .left-container .percentage .circle {
  font-family: "Inter", sans-serif;
}
.section-container .left-container .percentage .circle:first-child {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #253be2;
  position: relative;
  z-index: 2;
}
.section-container .left-container .percentage .circle:last-child {
  width: 266px;
  height: 266px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #803ff4;
  position: relative;
  z-index: 1;
  top: 150px;
  right: 100px;
}
.section-container .left-container .percentage .circle:first-child h2 {
  font-size: 3.5rem;
  font-weight: 700;
}
.section-container .left-container .percentage .circle:last-child h2 {
  font-size: 2.5rem;
}
.section-container .left-container .percentage .circle p {
  font-size: 1.25rem;
  font-weight: 700;
}
.section-container .right-container {
  background-image: url("../../assets/annual-report-2024/section21-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postpaid-photosource {
  color: #ffffff;
  font-size: 10px !important;
  position: relative;
  bottom: -420px;
  left: 80px;
}

.left-container .text-header a {
    color: #ffffff;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }

  @media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 850px;
  }
  .section-container .left-container {
    padding-top: 6rem;
  }
  .section-container .left-container .text-header h1 {
    font-size: 1.2rem;
  }
  .section-container .left-container .text-header p {
    font-size: 0.725rem;
  }
  .section-container .left-container .percentage .circle:first-child {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #253be2;
    position: relative;
    z-index: 2;
  }
  .section-container .left-container .percentage .circle:last-child {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #803ff4;
    position: relative;
    z-index: 1;
    top: 90px;
    right: 40px;
  }
  .section-container .left-container .percentage .circle:first-child h2 {
    font-size: 2rem;
  }
  .section-container .left-container .percentage .circle:last-child h2 {
    font-size: 1.5rem;
  }
  .section-container .left-container .percentage .circle p {
    font-size: 0.75rem;
  }
}
</style>
