<template>
  <div class="trend-consumen-age-pie-chart">
    <ApexChart
      type="pie"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "trend-consumen-age-piechart",
  props: {
    under18: Number,
    between18And24: Number,
    between25And34: Number,
    between35And44: Number,
    between45And54: Number,
    between55And64: Number,
    over64: Number,
  },
  data: function () {
    return {
      chartOptions: {
        labels: [
          "<18",
          "18 - 24",
          "25 - 34",
          "35 - 44",
          "45 - 54",
          "55 - 64",
          ">64",
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "14px",
          fontFamily: "Inter, Arial",
          offsetY: 50,
          offsetX: -100,
          position: "right",
          horizontalAlign: "center",
          width: 200,
          height: 450,
          markers: {
            width: 18,
            height: 18,
            radius: 9,
            offsetX: -5,
            offsetY: 3,
            fillColors: [
              "#4BC0C0",
              "#FFCD56",
              "#FF6384",
              "#FF9F40",
              "#E4E5E7",
              "#9966FF",
              "#36A2EB",
            ],
          },
          itemMargin: {
            vertical: 10,
          },
          labels: {
            colors: [
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
            ],
          },
          formatter: function (label, opts) {
            const percentage = opts.w.config.series[opts.seriesIndex];
            return `<div>${label}</div>
                            <div class="piechart-legend-label">${percentage.toFixed(
                              2
                            )}%
                            </div>
                            `;
          },
        },
        fill: {
          colors: [
            "#4BC0C0",
            "#FFCD56",
            "#FF6384",
            "#FF9F40",
            "#E4E5E7",
            "#9966FF",
            "#36A2EB",
          ],
        },
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 1512,
            options: {
              chart: {
                width: 448.5,
              },
              legend: {
                offsetY: -40,
                offsetX: -20,
                width: 0,
                height: 448.5,
                itemMargin: {
                  vertical: 5,
                },
              },
            },
          },
          {
            breakpoint: 1512,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                offsetY: 20,
                offsetX: -20,
                itemMargin: {
                  vertical: 10,
                },
              },
            },
          },
        ],
      },
      series: [
        this.under18,
        this.between18And24,
        this.between25And34,
        this.between35And44,
        this.between45And54,
        this.between55And64,
        this.over64,
      ],
    };
  },
};
</script>

<style>
.trend-consumen-age-pie-chart .apexcharts-legend-series {
  display: flex;
}

.trend-consumen-age-pie-chart .piechart-legend-label {
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
}

.trend-consumen-age-pie-chart .apexcharts-legend-text *:nth-child(1) {
  font-size: 12px;
  font-weight: normal;
  margin-top: 3px;
  opacity: 0.8;
}

.trend-consumen-age-pie-chart .apexcharts-canvas svg {
  overflow: visible;
}

.trend-consumen-age-pie-chart .apexcharts-canvas svg foreignObject {
  overflow: visible;
}
</style>
