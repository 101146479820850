<template>
    <div class="pelindo-container">
      <h4 data-aos="fade-down" data-aos-duration="3000">PELINDO</h4>
      <div data-aos="fade-right" data-aos-duration="3000">
        <div class="pelindo-highlights">
          <div class="pelindo-highlights-item">
            <img
              class="pelindo-icon"
              src="@/assets/annual-report-2024/calendar_icon.png"
              alt="pelindo-rush-month"
            />
            <div>
              <p>Hari paling ramai</p>
              <p>Senin</p>
            </div>
          </div>
          <div class="pelindo-highlights-item">
            <img
              src="@/assets/annual-report-2024/clock_icon.png"
              alt="pelindo-rush-hour"
            />
            <div>
              <p>Jam paling ramai</p>
              <p>8 Pagi - 3 Sore</p>
            </div>
          </div>
        </div>
        <div class="pelindo-highlights-chart">
          <div class="pelindo-highlights-item">
            <img
              src="@/assets/annual-report-2024/visitor_icon.png"
              alt="pelindo-visitor-gender"
            />
            <p>Gender Pengguna</p>
          </div>
          <PelindoGenderBarChart />
        </div>
      </div>
      <div data-aos="fade-left" data-aos-duration="3000" class="pelindo-highlights-chart">
        <div class="pelindo-highlights-item">
          <img
            src="@/assets/annual-report-2024/visitor_icon.png"
            alt="pelindo-visitor-age"
          />
          <p>Usia Pengguna</p>
        </div>
        <div class="pelindo-age-pie-chart">
            <AgePieChart
                :under18="6.44"
                :between18And24="26.51"
                :between25And34="37.98"
                :between35And44="18.67"
                :between45And54="7.85"
                :between55And64="1.49"
                :over64="0.24"
            />
            <div class="pelindo-chart-shortdesc">
                <p><span>Lebih dari 50% penumpang Pelindo adalah milenial dan Gen Z</span></p>
                <p class="pelindo-location-note">*Lokasi Pelindo yang digunakan sebagai sampel terdiri<br />
                  dari Jakarta Utara, Semarang, Surabaya</p>
            </div>
        </div>
      </div>
      <p class="pelindo-photosource">Photo Source: SWA.co.id</p>
    </div>
  </template>

<script>
import AgePieChart from './AgePieChart.vue';
import PelindoGenderBarChart from './PelindoGenderBarChart.vue';

    export default {
        name: 'pelindo-container',
        components: { AgePieChart, PelindoGenderBarChart }
    };
</script>

<style>
.pelindo-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(~@/assets/annual-report-2024/bg_pelindo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  row-gap: 3rem;
  align-items: baseline;
}

.pelindo-container > * {
  grid-column: -1 / 1;
}

.pelindo-container > h4 {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 4px;
  grid-column: -1 / 1;
}

.pelindo-container > p {
  text-transform: none;
  font-size: medium;
  font-weight: 200;
  max-width: 500px;
  margin-top: -2rem;
  grid-column: -1 / 1;
}

.pelindo-container .pelindo-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  row-gap: 2rem;
  margin-bottom: 3rem;
}

.pelindo-container .pelindo-highlights-item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 30px auto;
  align-items: center;
}

.pelindo-container .pelindo-highlights-item img {
  max-width: 100%;
}

.pelindo-container .pelindo-highlights-item p {
  font-family: "Inter", sans-serif;
  line-height: 25px;
}

.pelindo-container .pelindo-highlights-item p + p {
  font-weight: 900;
  letter-spacing: 1px;
}

.pelindo-container .pelindo-highlights-chart {
  display: grid;
  row-gap: 1.5rem;
}

.pelindo-container .pelindo-age-pie-chart {
  margin-top: 2rem;
}

.pelindo-chart-shortdesc {
    margin-top: 40px;
}

.pelindo-chart-shortdesc p {
    margin-bottom: 20px;
}

.pelindo-chart-shortdesc .pelindo-location-note {
  font-size: 12px;
}

.pelindo-chart-shortdesc p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.pelindo-chart-shortdesc p span {
  font-weight: 900;
}

.pelindo-photosource {
  font-size: 10px !important;
  position: relative;
  left: 50px;
  top: 100px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media only screen and (min-width: 1271px) {
  .pelindo-container > * {
    grid-column: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .pelindo-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .pelindo-container > .pelindo-highlights-chart {
    grid-column: span 2;
  }

  .pelindo-container > .pelindo-age-pie-chart {
    grid-column: span 2;
  }
}
</style>
