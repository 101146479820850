<template>
  <div class="positive-feedback-container">
    <div data-aos="fade-right" data-aos-duration="3000" class="positive-feedback-wrapper">
      <div class="feedback-title">
        <h4>Area Higospot Yang<br />
          Sering Mendapatkan<br />
          Ulasan Positif
        </h4>
      </div>

      <br />

      <div class="short-desc">
        <p><span>Kota Semarang, Bandung, Bekasi adalah kota-kota</span><br />
          <span>dengan pengunjung yang sensitif terhadap harga,</span><br />
          dimana pengunjung di kota tersebut dapat<br />
          mengubah keputusan dengan adanya perubahan<br />
          harga yang tidak signifikan, <span>sedangkan Surabaya,</span><br />
          <span>Tangerang Selatan, dan Bali mempunyai</span><br />
          <span>pengunjung yang tidak sensitif terhadap</span><br />
          <span>perubahan harga.</span>
        </p>

        <br />

        <p>
          Dilain halnya terhadap <span>kota Jakarta, yang</span><br />
          <span>mempunyai standar yang cukup rata di semua</span><br />
          <span>kategori, dimana pengunjung di Jakarta</span><br />
          <span>mempunyai ekspektasi yang tinggi di seluruh</span><br />
          <span>kategori.</span>
        </p>
      </div>
      <p class="positive-photosource">Photo Source: Freepik</p>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="graph-wrapper">
      <PositiveFeedbackHeatMapChart />
      <p><span>Note:</span><br />
        Nilai persentase ini adalah persentase kepuasan dari customer terhadap 5 komponen tersebut.<br />
        *Kota lainnya merupakan gabungan dari 5 kota
      </p>
    </div>
  </div>
</template>

<script>
import PositiveFeedbackHeatMapChart from "./PositiveFeedbackHeatmapChart.vue";

export default {
  name: "positive-feedback-container",
  components: {
    PositiveFeedbackHeatMapChart,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
};
</script>

<style>
.positive-feedback-container {
  background-color: #803ff4;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.positive-feedback-container > :nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.positive-feedback-container > :nth-child(2) {
  display: block;
  flex-direction: column;
  grid-column-start: 3;
  grid-column-end: 7;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
  align-items: center;
}

.positive-feedback-container h4,
.feedback-title p {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.positive-feedback-container p {
  padding-left: 0px;
}

.positive-feedback-wrapper {
  background-image: url(~@/assets/annual-report-2024/bg_positive-feedback_person.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.graph-wrapper p {
  margin-left: 160px;
}

.short-desc p,
.graph-wrapper p {
  font-family: "Inter", sans-serif;
}

.graph-wrapper p  {
  font-size: 12px;
  line-height: 1.4;
}

.short-desc p span,
.graph-wrapper p span {
  font-weight: 900;
}

.positive-photosource {
  font-size: 10px;
  position: relative;
  left: 0px;
  top: 30px;
}

@media only screen and (max-width: 1024px) {
  .positive-feedback-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .positive-feedback-container > :nth-child(1) {
    padding-left: 1rem;
  }

  .positive-feedback-container h4,
  .feedback-title p {
    font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  }

  .short-desc {
    margin-top: 20px;
  }

  .short-desc p {
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
  }

  .positive-feedback-container > :nth-child(2) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .positive-feedback-container {
    background-color: #803ff4;
    color: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .positive-feedback-container > :nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: center;
    padding-left: 2;
    padding-right: 2rem;
  }

  .positive-feedback-container > :nth-child(2) {
    display: block;
    flex-direction: column;
    grid-column-start: 3;
    grid-column-end: 6;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
    padding-bottom: 3rem;
    align-items: center;
  }

  .positive-photosource {
  display: none;
}
}

@media only screen and (min-width: 1271px) {
  .positive-feedback-container > :nth-child(1) {
    padding-left: 2rem;
  }
}

@media only screen and (min-width: 1271px) and (max-width: 1280px) {
  .positive-photosource {
    display: none;;
  }
}
</style>
