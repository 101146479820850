<template>
    <div class="factor-container">
        <div data-aos="fade-up" data-aos-duration="2000">
          <div class="factor-title">
            <h4>Faktor Terpenting<br />
              Pemilihan Tempat<br />
              Nongkrong
            </h4>
          </div>
          <div class="factor-short-desc">
            <div>
              <div class="factor-icons-wrapper">
                <div class="factor-icons">
                  <span>1.</span> <img src="@/assets/annual-report-2024/uil_food.png" alt="food-factor-icon">
                  <span>2.</span> <img src="@/assets/annual-report-2024/mingcute_drink-line.png" alt="drink-factor-icon">
                  <span>3.</span> <img src="@/assets/annual-report-2024/solar_tag-price-outline.png" alt="pricetag-factor-icon">
                </div>
              </div>
              <p>Bagi generasi Z rasa makanan,<br />
               minuman, dan harga jadi faktor penting<br />
               dalam pemilihan coffeeshop
              </p>
            </div>
            <div class="factor-short-desc">
              <div class="factor-icons-wrapper">
                <div class="factor-icons">
                  <span>1.</span> <img src="@/assets/annual-report-2024/ri_service-line.png" alt="service-factor-icon">
                  <span>2.</span> <img src="@/assets/annual-report-2024/uil_smile.png" alt="smile-factor-icon">
                </div>

              </div>
              <p>
              Bagi milenial, faktor paling penting<br />
              dalam pemilihan coffee shop adalah<br />
              suasana yang nyaman dan pelayanan<br />
              yang memuaskan.
              </p>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" data-aos-duration="2000" class="factor-insight">
          <div class="factor-circle-desc">
            <p>
            Sehingga jika ingin menarik hati generasi Z dan<br />
            milenial untuk datang ke coffee shop, <span>kamu perlu</span><br />
            <span>menciptakan rasa makanan dan minuman yang</span> <br />
            <span>tidak hanya nikmati, tapi juga ramah di kantong.</span><br />
            <span>Serta suasana coffee shop yang nyaman dengan</span><br />
            <span>pelayanan yang ramah.</span> Hal ini karena esensi<br />
            coffee shop bukan hanya tentang membeli kopi,<br />
            tapi juga menciptakan lingkungan yang nyaman<br />
            untuk berkumpul, bekerja, atau hanya sekedar<br />
             untuk bersantai
          </p>
          </div>
          <p class="factor-photosource">Photo Source: Unsplash</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'factor-container',
        data: function () {
        return {
          windowWidth: 0,
          windowHeight: 0,
        lang: ''
        }
    },
}
</script>

<style>
.factor-container {
    min-height: calc(100svh - 80px);
    color: #ffffff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.factor-container > :nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: start;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #803FF4;
}

.factor-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  grid-column-start: 3;
  grid-column-end: 6;
  justify-content: center;
  background-color: #3A51FF;
}

.factor-container h4 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 3rem;
}

.factor-container p {
  padding-left: 0px;
}

.factor-title p {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.factor-container .factor-short-desc {
  width: 100%;
  margin: 0px;
  padding-top: 50px;
  line-height: 1.4;
}

.factor-circle-desc {
  width: 500px;
  height: 500px;
  line-height: 500px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background-color: #000DAE;
  opacity: 0.7;
}

.factor-short-desc p {
  font-family: "Inter", sans-serif;
  width: -webkit-fill-available;
  font-size: 1.3rem;
}

.factor-container div:nth-child(2) p:first-child {
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  font-weight: 200;
}

.factor-insight {
  background-image: url(~@/assets/annual-report-2024/bg_coffshop_genz.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
}

.factor-insight p {
  font-family: "Inter", sans-serif;
  text-align: center;
  line-height: 1.4;
}

.factor-insight p span {
  font-weight: 900;
}

.factor-short-desc p {
  margin-top: 25px;
}

.factor-icons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.factor-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.factor-icons span {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  margin-right: 5px;
}

.factor-icons-wrapper img {
  margin-right: 30px;
  width: 35px;
  height: 35px;
}

.factor-circle-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .factor-insight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .factor-photosource {
    font-size: 10px;
    position: relative;
    top: 120px;
    left: 230px;
  }

@media only screen and (max-width: 1024px) {
  .factor-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .factor-container div:nth-child(2) p:first-child {
    font-size: 1rem;
    text-align: initial;
  }
}
</style>
