<template>
  <ApexChart
    width="100%"
    height="600"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "asdp-gender-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#F2ADFF", "#00E2F4"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "bar",
          height: 430,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 8,
            borderRadiusApplication: "end",
            columnWidth: 30,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          show: false,
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
          itemMargin: {
            vertical: 30,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          formatter: (value) => `${value}%`,
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 14,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
          ],
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: 16,
            },
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: "Female",
          data: [
            27.87, 24.16, 32.72, 34.71, 32.72, 32.0, 39.48, 37.08, 37.77, 36.35,
          ],
        },
        {
          name: "Male",
          data: [
            72.13, 75.84, 67.28, 65.29, 65.85, 63.0, 60.52, 62.92, 62.23, 63.65,
          ],
        },
      ],
    };
  },
};
</script>
