<template>
    <div class="topten-container">
        <div data-aos="fade-right" data-aos-duration="3000">
            <h4>Top Coffee Shop yang Mendapatkan<br />
                Feedback Excellent dari Pengunjung
            </h4>
            <p>Coffee shop yang mendapatkan excellent feedback dari pengunjung berdasarkan kategori<br />
                Minuman, Suasana, Pelayanan, dan Harga.
            </p>

            <div class="categories-wrapper">
                <div>
                    <p class="title-category">Kategori Minuman</p>
                    <div class="drink-category-wrapper">
                        <div>
                            <img src="../../assets/annual-report-2024/logo_kopi_roemah_kesambi.jpg" alt="logo kopi roemah kesambi">
                            <p>Kopi Roemah<br />
                                Kesambi
                            </p>
                            <span>
                                <a href="https://www.instagram.com/kopiroemahkesambi/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/annual-report-2024/logo_toodz_house.jpg" alt="logo toodz house">
                            <p>Toodz House<br />
                                Cipete
                            </p>
                            <span>
                                <a href="https://www.instagram.com/toodzhouse/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="title-category">Kategori Suasana</p>
                    <div class="ambience-category-wrapper">
                        <div>
                            <img src="../../assets/annual-report-2024/logo_tuju_hari.jpg" alt="logo tuju hari">
                            <p>TujuHari<br />
                                Coffee
                            </p>
                            <span>
                                <a href="https://www.instagram.com/tujuhari_/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/annual-report-2024/logo_third_wave.jpg" alt="logo third wave">
                            <p>Third Wave<br />
                                Coffee
                            </p>
                            <span>
                                <a href="https://www.instagram.com/thirdwavecoffeeco/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="categories-wrapper">
                <div>
                    <p class="title-category">Kategori Pelayanan</p>
                    <div class="service-category-wrapper">
                        <div>
                            <img src="../../assets/annual-report-2024/logo_ruang_kopi.jpg" alt="logo ruang kopi">
                            <p>Ruang Kopi</p>
                            <span>
                                <a href="https://www.instagram.com/ruuangkopi/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/annual-report-2024/logo_dua_coffee.jpg" alt="logo dua coffee">
                            <p>Dua Coffee</p>
                            <span>
                                <a href="https://www.instagram.com/duacoffee/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="title-category">Kategori Harga</p>
                    <div class="price-category-wrapper">
                        <div>
                            <img src="../../assets/annual-report-2024/logo_pisto.jpg" alt="logo pisto">
                            <p>Pisto Coffee &<br />
                               Eatery
                            </p>
                            <span>
                                <a href="https://www.instagram.com/pisto.bali/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/annual-report-2024/logo_kopikalyan.jpg" alt="logo Kopikalyan">
                            <p>Kopikalyan</p>
                            <span>
                                <a href="https://www.instagram.com/kopikalyan/?hl=en" class="fa fa-instagram"></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="3000" class="topten-desc">
            <p>Dua Coffee berhasil jadi salah satu coffee shop yang<br />
               mendapatkan persentase feedback positif. Tentu hal<br />
               ini menarik kami untuk melakukan interview secara <br />
               langsung untuk mendapatkan insight menarik tentang <br />
               strategi apa yang telah dilakukan oleh Dua Coffee.<br />
            </p>

            <br />

            <p>“<span>Memberikan kehangatan layaknya rumah</span><br />
                <span>kedua</span> kepada semua pengunjung yang<br />
                datang berhasil mengantarkan Dua Coffee<br />
                mendapatkan <i>feedback excellent</i>. <span>Memahami</span><br />
                <span>pengunjung lewat insight data yang dimiliki</span><br />
                <span>membuat Dua Coffee berhasil membuat</span> <br />
                <span>promo menarik, menciptakan menu baru</span> <br />
                <span></span>yang diinginkan, dan melakukan kolaborasi <br />
                <span>dengan menghadirkan <i>activation</i> unik,</span> <br />
                sehingga membuat pengunjung tidak hanya <br />
                tertarik tapi juga betah menghabiskan waktu <br />
                mereka di sana.”
            </p>
            <p class="topten-photosource">Photo Source: Instagram @duacoffee</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'topten-container',
        data: function () {
        return {
        windowWidth: 0,
        windowHeight: 0,
        lang: ''
        }
    },
}
</script>

<style>
.topten-container {
    background-color: #253BE2;
    color: #ffffff;
    min-height: calc(100svh - 80px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.topten-container > :nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 4;
    justify-content: start;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #253BE2;
}

.topten-container > :nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-column-start: 4;
    grid-column-end: 7;
    justify-content: center;
    background-color: #3A51FF;
    padding-left: 4rem;
    padding-right: 4rem;
}

.topten-container h4 {
    text-transform: uppercase;
    font-family: "RMConnect";
    font-size: 2rem;
    margin-bottom: 10px;
    padding-top: 3rem;
    line-height: 1.4;
}

.topten-desc {
    background-image: url(~@/assets/annual-report-2024/top_coffeshop.jpg);
    background-size: cover;
    background-size: no-repeat;
}

.topten-desc p {
    font-size: medium;
    font-family: "Inter", sans-serif;
    line-height: 1.4;
    text-transform: none;
    text-align: left;
    margin-top: 20px;
    margin-left: 80px;
}

.topten-container p {
    font-family: "Inter", sans-serif;
    line-height: 1.4;
}

.topten-desc p span {
    line-height: 1.4;
    font-weight: 900;
}

.categories-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.categories-wrapper {
    margin-top: 70px;
}

.categories-wrapper .title-category {
    font-family: "RMConnect";
}

.title-category {
    font-size: 1.75rem;
    font-weight: 900;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.drink-category-wrapper,
.service-category-wrapper {
    margin-right: 140px;
}

.drink-category-wrapper,
.ambience-category-wrapper,
.service-category-wrapper,
.price-category-wrapper  {
    margin-left: 20px;
}

.drink-category-wrapper > :nth-child(1),
.ambience-category-wrapper > :nth-child(1),
.service-category-wrapper > :nth-child(1),
.price-category-wrapper > :nth-child(1) {
    margin-right: 15px;
}

.drink-category-wrapper > :nth-child(2),
.ambience-category-wrapper > :nth-child(2),
.service-category-wrapper > :nth-child(2),
.price-category-wrapper > :nth-child(2) {
    margin-left: 15px;
}

.drink-category-wrapper,
.ambience-category-wrapper,
.service-category-wrapper,
.price-category-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.drink-category-wrapper img,
.ambience-category-wrapper img,
.service-category-wrapper img,
.price-category-wrapper img {
    width: 100px;
    height: 100px;
}

.drink-category-wrapper p,
.ambience-category-wrapper p,
.service-category-wrapper p,
.price-category-wrapper p {
    font-size: 16px;
    font-weight: 200;
}

.fa-instagram {
    margin-top: 10px;
}

.fa-instagram:before {
    content: "\f16d";
    color: #ffffff;
}

.topten-desc .topten-photosource {
  font-size: 10px;
  position: relative;
  left: 0px;
  top: 80px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
  margin-top: 100px;
}

@media only screen and (max-width: 1024px) {
  .topten-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1285px) {
    .topten-container h4 {
        font-size: 1.3rem;
    }

    .topten-container > :nth-child(1) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .title-category {
        font-size: 1.2rem;
    }

    .drink-category-wrapper,
    .ambience-category-wrapper,
    .service-category-wrapper,
    .price-category-wrapper {
        margin-left: 0px;
    }

    .drink-category-wrapper img,
    .ambience-category-wrapper img,
    .service-category-wrapper img,
    .price-category-wrapper img {
        width: 80px;
        height: 80px;
    }

    .drink-category-wrapper,
    .service-category-wrapper {
        margin-right: 85px;
    }

    .drink-category-wrapper p,
    .ambience-category-wrapper p,
    .service-category-wrapper p,
    .price-category-wrapper p {
        font-size: 14px;
    }

    .topten-container > :nth-child(2) {
        padding-left: 6rem;
        padding-right: 1rem;
    }

    .topten-container p {
        font-size: 12px;
    }

}
</style>
