<template>
  <div class="hangout-container">
    <div data-aos="fade-right" data-aos-duration="3000">
      <h4>Tempat Nongkrong Favorit Generasi Z dan Milenial</h4>
      <p>
        Berdasarkan data yang kami kumpulkan dengan mengelompokkan tempat
        nongkrong menjadi beberapa kategori, hasilnya antara Generasi Z dan
        Milenial memiliki tempat nongkrong favorite yang sama yaitu coffee shop.
      </p>

      <br />

      <p>Namun, <span>Gen Z</span> terbukti mempunyai <span>kecenderungan lebih tinggi</span> untuk
        memilih <span>Coffee Shop</span> dari milenial sebagai tempat untuk menghabiskan
        waktu dengan kerabat dan keluarganya.
      </p>
      <p class="photo-source">Photo Source: Unsplash</p>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="piechart-container">
      <HangoutSpotGenerasiPieChart />
      <HangoutSpotMillenialPieChart />
    </div>
  </div>
</template>

<script>
import HangoutSpotGenerasiPieChart from "./HangoutSpotGenerasiZPieChart.vue";
import HangoutSpotMillenialPieChart from "./HangoutSpotMillenialPieChart.vue";

export default {
  name: "hangout-container",
  components: {
    HangoutSpotGenerasiPieChart,
    HangoutSpotMillenialPieChart,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
};
</script>

<style>
.hangout-container {
  background-image: url(~@/assets/annual-report-2024/image_coffee_shop.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.hangout-container h4,
.hangout-container p {
  padding-left: 4rem;
  padding-right: 4rem;
}

.hangout-container h4 {
  padding-top: 3rem;
  font-family: "RMConnect";
}

.hangout-container > :nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  justify-content: center;
}

.hangout-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: #445bfe;
}

.hangout-container h4 {
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 30px;
  margin-top: 20px;
}

.hangout-container img {
  margin-top: 20px;
}

.hangout-container .piechart-container div:nth-child(1) p {
  font-weight: 900;
}

.hangout-container p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.hangout-container p span {
  font-weight: 900;
}

.photo-source {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 8px;
}

.hangout-container .piechart-container {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 1024px) {
  .hangout-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1370) {
  .hangout-container .piechart-container {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}
</style>
