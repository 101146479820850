<template>
    <div class="age-pie-chart">
      <ApexChart
        type="pie"
        width="650px"
        :options="chartOptions"
        :series="series"
      ></ApexChart>
    </div>
  </template>

  <script>
  export default {
    name: "age-piechart",
    props: {
      under18: Number,
      between18And24: Number,
      between25And34: Number,
      between35And44: Number,
      between45And54: Number,
      between55And64: Number,
      over64: Number,
    },
    data: function () {
      return {
        chartOptions: {
          labels: [
            "<18",
            "18 - 24",
            "25 - 34",
            "35 - 44",
            "45 - 54",
            "55 - 64",
            ">64",
          ],
          dataLabels: {
            enabled: false,
          },
          legend: {
            fontSize: "14px",
            offsetY: 20,
            position: "right",
            horizontalAlign: "center",
            width: 200,
            height: 290,
            markers: {
              width: 18,
              height: 18,
              radius: 9,
              offsetX: -5,
              offsetY: 3,
              fillColors: [
                "#4BC0C0",
                "#FFCD56",
                "#FF6384",
                "#FF9F40",
                "#E4E5E7",
                "#9966FF",
                "#36A2EB",
              ],
            },
            labels: {
              colors: [
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
              ],
            },
            formatter: function (label, opts) {
              const percentage = opts.w.config.series[opts.seriesIndex];
              return `<div>${label}</div>
                              <div class="piechart-legend-label">${percentage.toFixed(
                                2
                              )}%
                              </div>
                              `;
            },
          },
          fill: {
            colors: [
              "#4BC0C0",
              "#FFCD56",
              "#FF6384",
              "#FF9F40",
              "#E4E5E7",
              "#9966FF",
              "#36A2EB",
            ],
          },
          stroke: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        series: [
          this.under18,
          this.between18And24,
          this.between25And34,
          this.between35And44,
          this.between45And54,
          this.between55And64,
          this.over64,
        ],
      };
    },
  };
  </script>

  <style>
  .age-pie-chart .apexcharts-legend-series {
    display: flex;
  }

  .age-pie-chart .piechart-legend-label {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
  }

  .age-pie-chart .apexcharts-legend-text *:nth-child(1) {
    font-size: 12px;
    font-weight: normal;
    margin-top: 3px;
    opacity: 0.8;
  }

  .age-pie-chart .apexcharts-canvas svg {
    overflow: visible;
  }

  .age-pie-chart .apexcharts-canvas svg foreignObject {
    overflow: visible;
  }
  </style>
