<template>
  <div class="payment-pie-chart">
    <ApexChart
      type="pie"
      width="667px"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "payment-piechart",
  props: {
    cash: Number,
    qris: Number,
    creditCard: Number,
    debitCard: Number
  },
  data: function () {
    return {
      chartOptions: {
        labels: ["Tunai", "QRIS", "Credit Card", "Debit Card"],
        dataLabels: {
          enabled: false
        },
        legend: {
          fontSize: "12px",
          fontFamily: "Inter, Arial",
          offsetY: 50,
          offsetX: -100,
          position: "right",
          horizontalAlign: "center",
          width: 200,
          height: 400,
          markers: {
            width: 18,
            height: 18,
            radius: 9,
            offsetX: -5,
            offsetY: 3,
            fillColors: ["#FF6384", "#36A2EB", "#FFCD56", "#D8F9FB"]
          },
          itemMargin: {
            vertical: 12
          },
          labels: {
            colors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"]
          },
          formatter: function (label, opts) {
            const percentpayment = opts.w.config.series[opts.seriesIndex];
            return `<div>${label}</div>
                            <div class="piechart-legend-label">${percentpayment.toFixed(
                              1
                            )}%
                            </div>
                            `;
          }
        },

        fill: {
          colors: ["#FF6384", "#36A2EB", "#FFCD56", "#D8F9FB"]
        },
        stroke: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 1512,
            options: {
              legend: {
                offsetY: -70,
                offsetX: -20,
                itemMargin: {
                  vertical: 10
                }
              }
            }
          }
        ]
      },
      series: [this.cash, this.qris, this.creditCard, this.debitCard]
    };
  }
};
</script>

<style>
.payment-pie-chart .apexcharts-legend-series {
  display: flex;
}
.payment-pie-chart .apexcharts-legend {
  top: 0;
}

.payment-pie-chart .piechart-legend-label {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.payment-pie-chart .apexcharts-legend-text *:nth-child(1) {
  font-size: 20px;
  font-weight: normal;
  margin-top: 3px;
  opacity: 0.8;
}

.payment-pie-chart .apexcharts-canvas svg {
  overflow: visible;
}

.payment-pie-chart .apexcharts-canvas svg foreignObject {
  overflow: visible;
}
</style>
