<template>
  <div class="negative-feedback-container">
    <div data-aos="fade-right" data-aos-duration="3000" class="left">
      <div />
      <p>
        Selain feedback positif,<br />
        <strong>
          kami juga mengumpulkan feedback negatif untuk mengetahui apa yang
          sering dikeluhkan pengunjung ketika mereka datang ke lokasi.
        </strong>
        <br />
        <br />
        Tidak hanya sebatas coffee shop dan resto tapi juga lokasi lainnya,
        seperti hotel, shopping destination, dan juga
        <i>food joint</i> (Food Court yang terdiri dari banyaknya tenant)
      </p>
    </div>
    <div data-aos="zoom-in" data-aos-duration="3000" class="center">
      <NegativeFeedbackHeatMapChart />
      <p><span>Note:</span><br />
        *Kota lainnya merupakan gabungan dari 5 kota
      </p>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="right">
      <div />
      <p>
        Hasilnya dari 4 kategori (Minuman,<br />
        Suasana, Makanan, Harga, dan <br />
        Pelayanan),
        <strong>harga menjadi kategori yang paling sering dikeluhkan.</strong>
        <span>
          Adapun kota Semarang dan<br />
          juga Tangerang Selatan<br />
          mempunyai persentase<br />
          yang tinggi dalam hal <strong>Food</strong><br />
          dan <strong>Drink</strong>, yang mencakup<br />
          rasa, kualitas, maupun<br />
          presentasi dari makanan<br />
          atau minuman tersebut.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import NegativeFeedbackHeatMapChart from "./NegativeFeedbackHeatmapChart.vue";

export default {
  name: "negative-feedback-container",
  components: {
    NegativeFeedbackHeatMapChart,
  },
};
</script>

<style>
.negative-feedback-container {
  background-color: #803ff4;
  color: #ffffff;
  display: grid;
  /* min-height: 100svh; */
  grid-template-columns: minmax(300px, 1fr) 3fr;
  column-gap: 2rem;
  overflow: clip;
}

.negative-feedback-container > * {
  display: grid;
}

.negative-feedback-container .left {
  grid-template-columns: 0px 4rem auto;
  grid-template-rows: 4rem 4rem;
}

.negative-feedback-container .left > div {
  display: grid;
  background-color: #273be4;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  max-width: 250px;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
}

.negative-feedback-container .left > p {
  grid-row-start: 3;
  grid-column-start: 3;
  font-size: 1.1rem;
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  max-width: 500px;
}

.negative-feedback-container .right > p br {
  display: none;
}

.negative-feedback-container .left > p > strong {
  font-weight: 600;
  font-size: 1.3rem;
}

.negative-feedback-container .center {
  padding-top: 4rem;
  align-items: center;
}

.negative-feedback-container .right {
  grid-row: 2;
  grid-column: -1 / 1;
  grid-template-columns: 3rem auto 4rem;
  align-items: center;

  padding-bottom: 3rem;
  grid-column: 2;
}

.negative-feedback-container .right > div {
  display: none;
  background-color: #5400d4;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  width: 160%;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 3;
}

.negative-feedback-container .right > p {
  font-family: "Inter", sans-serif;
  line-height: 1.1;
  grid-row-start: 1;
  grid-column-start: 2;
  font-size: 1rem;
  text-align: left;
  max-width: 500px;
  margin-left: auto;
}

.negative-feedback-container .right > p > span {
  display: block;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.center span {
  font-weight: 900;
  font-size: 12px;
}

.center p {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  margin-left: 165px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .negative-feedback-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .negative-feedback-container .right > p{
    text-align: left;
  }
}

@media (min-width: 1400px) {
  .negative-feedback-container {
    min-height: 100svh;
    grid-template-columns: minmax(300px, 1fr) 3fr minmax(350px, 1fr);
  }

  .negative-feedback-container .center {
    padding-bottom: 4rem;
  }

  .negative-feedback-container .right {
    grid-row: unset;
    grid-column: unset;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding: 0;
    grid-template-columns: 3rem auto 1rem;
  }

  .negative-feedback-container .right > div {
    display: grid;
  }

  .negative-feedback-container .right > p {
    line-height: 1.2;
    font-size: 1rem;
    max-width: unset;
    margin-left: unset;
  }

  .negative-feedback-container .right > p br {
    display: block;
  }

  .negative-feedback-container .right > p > span {
    font-size: 1rem;
  }
}
</style>
