<template>
  <ApexChart
    width="100%"
    height="600"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "coffee-fav-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#F2ADFF", "#00E2F4"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "bar",
          height: 430,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 16,
            borderRadiusApplication: "end",
            columnWidth: 40,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
          itemMargin: {
            vertical: 30,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          formatter: (value) => `${value}%`,
        },
        fill: {
          colors: ["#F2ADFF", "#00E2F4"],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 6,
          colors: ["transparent"],
        },
        xaxis: {
          tickAmount: 3,
          categories: ["Avocado Coffee", "Kopi Susu Gula Aren", "Long Black"],
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: 16,
            },
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: "Perempuan",
          data: [57.14, 38.24, 33.33],
        },
        {
          name: "Laki - laki",
          data: [42.86, 61.76, 66.67],
        },
      ],
    };
  },
};
</script>
