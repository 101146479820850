<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>Metode Pembayaran yang Sering Digunakan</h2>
      <p>
        Meski metode pembayaran non-tunai (cashless) lebih praktis, tapi
        nyatanya masih banyak orang yang memilih menggunakan pembayaran tunai.
        Terbukti sebanyak <strong>44.4% memilih tunai</strong> sebagai metode
        pembayarannya.
      </p>
    </div>
    <div class="analysis">
      <div data-aos="fade-right" data-aos-duration="1800" class="apex">
        <PaymentPieChart
          :cash="44.4"
          :credit-card="22.2"
          :debit-card="11.1"
          :qris="22.2"
        />
      </div>
      <div data-aos="fade-left" data-aos-duration="1800" class="result">
        <p>Jika dikategorikan berdasarkan usia,</p>
        <p><strong>75% Gen Z</strong> menyukai metode pembayaran tunai,</p>
        <p>
          sedangkan <strong>80% milenial</strong> menyukai metode pembayaran
          QRIS.
        </p>
      </div>
    </div>
    <p class="payment-photosource">Photo Source: Freepik</p>
  </div>
</template>

<script>
import PaymentPieChart from "./PaymentPieChart.vue";

export default {
  name: "method-payment",
  components: {
    PaymentPieChart,
  },
};
</script>

<style scoped>
.section-container {
  background-image: url("../../assets/annual-report-2024/section28-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 1080px;
  padding: 6rem 4rem;
}
.section-container .header {
  display: flex;
  gap: 1.625rem;
  flex-direction: column;
  color: white;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: space-between;
  align-items: center;
  max-width: 1512px;
  margin: auto;
}
.section-container .analysis .result {
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  gap: 40px;
  color: white;
  font-size: 2rem;
  max-width: 500px;
  font-weight: 300;
}
.section-container .header p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 911px;
}

.payment-photosource {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  top: -85px;
  left: 18px;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

/*@media screen and (max-width: 1512px) {
  .section-container {
    height: 750px;
  }
}*/
</style>
