<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>Kecepatan Layanan Internet Berdasarkan Area</h2>
    </div>
    <div class="analysis">
      <div data-aos="fade-up" data-aos-duration="1800" class="apex">
        <InternetByAreaBarChart />
      </div>
    </div>
    <p class="internet-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import InternetByAreaBarChart from "./InternetByAreaBarChart.vue";

export default {
  name: "internet-by-area",
  components: {
    InternetByAreaBarChart,
  },
};
</script>

<style scoped>
.section-container {
  background-image: url("../../assets/annual-report-2024/section23-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 1080px;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .header {
  color: white;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
  text-align: center;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1908px;
  margin: auto;
  gap: 2rem;
}
.section-container .analysis .apex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
}
.section-container .analysis .apex p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 790px;
  color: white;
}

.internet-photosource {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  left: 18px;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 900px;
  }
  .section-container .analysis .apex p {
    font-size: 1rem;
    max-width: 500px;
  }
}
</style>
