<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>Tren Perilaku Konsumen Selama Bulan Ramadan</h2>
    </div>
    <div class="analysis">
      <div data-aos="fade-right" data-aos-duration="1800" class="apex">
        <p>
          Selama bulan Ramadan perilaku konsumen di Indonesia tentu mengalami
          perubahan. Salah satunya adalah kebiasaan mereka melakukan buka puasa
          bersama keluarga atau teman di luar. Tak heran selama bulan Ramadan,
          Restaurant jadi lokasi yang paling disering untuk dikunjungi.
        </p>
        <TrendConsumenSecondChart />
      </div>
      <div data-aos="fade-left" data-aos-duration="1800" class="apex">
        <p>
          Berdasarkan usia, <strong>Gen Z dan Millenial</strong> jadi generasi
          yang
          <strong>paling sering makan di luar selama bulan ramadan.</strong>
        </p>
        <TrendConsumenAgePieChart
          :under18="3.17"
          :between18-and24="24.55"
          :between25And34="49.92"
          :between35And44="15.09"
          :between45And54="5.37"
          :between55And64="1.73"
          :over64="0.16"
        />
      </div>
    </div>
    <p class="trend-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import TrendConsumenAgePieChart from "./TrendConsumenAgePieChart.vue";
import TrendConsumenSecondChart from "./TrendConsumenSecondChart.vue";

export default {
  name: "method-payment",
  components: {
    TrendConsumenAgePieChart,
    TrendConsumenSecondChart,
  },
};
</script>

<style scoped>
.section-container {
  background-image: url("../../assets/annual-report-2024/section26-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 1080px;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .header {
  color: white;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1908px;
  margin: auto;
  gap: 2rem;
}
.section-container .analysis .apex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .analysis .apex p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 790px;
  color: white;
}

.trend-photosource {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  top: -21px;
  left: 18px;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 900px;
  }
  .section-container .analysis .apex p {
    font-size: 1rem;
    max-width: 500px;
  }
}
</style>
