<template>
  <div class="container">
    <div data-aos="fade-right" data-aos-duration="1500" class="circle">
      <h2>Setelah memahami kebiasaan Gen Z dan Millenial,</h2>
      <p>
        Ada beberapa insight yang bisa digunakan oleh para business owner atau
        marketer di 2024.
      </p>
    </div>
    <p class="genz-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
export default {
  name: "GenZHabit",
};
</script>

<style scoped>
.container {
  background-image: url("../../assets/annual-report-2024/section20-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  width: 100%;
  height: 1080px;
  padding: 4rem 0rem;
}
.container .circle {
  color: white;
  height: 58.4rem;
  max-width: 58.4rem;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 1.875rem;
  background-color: #253be2;
  padding-top: 15.875rem;
  padding-bottom: 15.875rem;
  padding-left: 6rem;
  padding-right: 6.2rem;
  border-radius: 50%;
  position: relative;
  right: 50px;
}
.container .circle h2 {
  font-size: 2.25rem;
  font-weight: 400;
}
.container .circle p {
  font-size: 3rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.genz-photosource {
  color: #ffffff;
  font-size: 10px;
  position: relative;
  top: -21px;
  left: 18px;
}

@media (max-width: 1024px) {
  .container {
    display: none;
  }
}
@media screen and (max-width: 1512px) {
  .container {
    height: 750px;
  }
  .container .circle {
    height: 40rem;
    max-width: 40rem;
  }
  .container .circle h2 {
    font-family: "Inter", sans-serif;
    font-size: 1.45rem;
  }
  .container .circle p {
    font-size: 2rem;
    font-family: "Inter", sans-serif;
  }
}
</style>
