<template>
  <div class="order-container">
    <aside data-aos="fade-down" data-aos-duration="3000">
      <h4>Kopi dan Menu<br />
        Yang Paling<br />
        Sering Dipesan<br />
        Oleh Generasi Z<br />
        Dan Millenial<br />
      </h4>
      <p class="order-photosource">Photo Source: Unsplash</p>
    </aside>
    <div data-aos="fade-up" data-aos-duration="3000">
      <div>
        <p>
          Indonesia terkenal dengan cita rasa kopi yang khas. Hal ini tentu
          tidak lepas dari biji kopi berkualitas yang banyak ditemukan di
          Indonesia. Biji kopi yang berasal dari Sumatra jadi biji kopi favorit
          karena memiliki citarasa dan aroma yang khas.
        </p>
        <CoffeeOrderFirstChart />
      </div>
      <div>
        <p>
          Sedangkan Kopi Susu Gula Aren jadi varian kopi favorit yang paling
          sering dipesan saat nongkrong.
        </p>
        <CoffeeOrderSecondChart />
      </div>
    </div>
  </div>
</template>
<script>
import CoffeeOrderFirstChart from "./CoffeeOrderFirstChart.vue";
import CoffeeOrderSecondChart from "./CoffeeOrderSecondChart.vue";

export default {
  name: "order-container",
  components: {
    CoffeeOrderFirstChart,
    CoffeeOrderSecondChart,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
};
</script>

<style>
.order-container {
  background-image: url(~@/assets/annual-report-2024/bg_text_coffeeorder.jpg);
  background-size: cover;
  color: #ffffff;
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.order-container h4 {
  font-family: "RMConnect";
  line-height: 1.4;
}

.order-container p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.order-container > aside {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  padding-inline: 20px;
  background-image: url(~@/assets/annual-report-2024/yearly_bg_coffeeorder.png);
  background-size: cover;
  text-transform: uppercase;
  border-bottom-right-radius: 100px;
}

.order-container > aside > * {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.order-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column-start: 3;
  grid-column-end: 6;
  padding: 4rem;
}

.order-container > div > div + div {
  margin-top: 4rem;
}

.order-container > aside .order-photosource {
  font-size: 10px;
  position: relative;
  left: 0px;
  top: 80px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media (max-width: 1024px) {
  .order-container {
    display: none;
  }
}

@media only screen and (min-width: 1271px) and (max-width: 1280px) {
  .order-container > aside .order-photosource {
    display: none;
  }
}
</style>
