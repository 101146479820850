<template>
    <div class="lrt-container">
      <h4 data-aos="fade-down" data-aos-duration="3000">LRT</h4>
      <div data-aos="fade-right" data-aos-duration="3000">
        <div class="lrt-highlights">
          <div class="lrt-highlights-item">
            <img
              src="@/assets/annual-report-2024/calendar_icon.png"
              alt="lrt-rush-day"
            />
            <div>
              <p>Hari paling ramai</p>
              <p>Selasa</p>
            </div>
          </div>
          <div class="lrt-highlights-item">
            <img
              src="@/assets/annual-report-2024/clock_icon.png"
              alt="lrt-rush-hour"
            />
            <div>
              <p>Jam paling ramai</p>
              <p>5 - 6 Sore</p>
            </div>
          </div>
        </div>
        <div class="lrt-highlights-chart">
          <div class="lrt-highlights-item">
            <img
              src="@/assets/annual-report-2024/visitor_icon.png"
              alt="lrt-visitor-gender"
            />
            <p>Gender Pengguna</p>
          </div>
          <LrtGenderBarChart :priaPercentage="66.52" :wanitaPercentage="33.48" />
        </div>
      </div>
      <div data-aos="fade-left" data-aos-duration="3000" class="lrt-highlights-chart">
        <div class="lrt-highlights-item">
          <img
            src="@/assets/annual-report-2024/visitor_icon.png"
            alt="lrt-visitor-age"
          />
          <p>Usia Pengguna</p>
        </div>
        <div class="lrt-age-pie-chart">
          <AgePieChart
            :under18="3.72"
            :between18And24="24.84"
            :between25And34="42.90"
            :between35And44="16.68"
            :between45And54="10.67"
            :between55And64="1"
            :over64="0.19"
          />
        </div>
        <p class="lrt-note">Lebih dari 65% penumpang LRT adalah milenial dan Gen Z</p>
      </div>
      <p class="lrt-photosource">Photo Source: Unsplash</p>
    </div>
  </template>

<script>
import LrtGenderBarChart from './LrtGenderBarChart.vue';
import AgePieChart from './AgePieChart.vue';

export default {
    name: 'lrt-container',
    components: { AgePieChart, LrtGenderBarChart }
}
</script>

<style>
.lrt-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(~@/assets/annual-report-2024/bg_lrt.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  row-gap: 3rem;
  align-items: baseline;
}

.lrt-container > * {
  grid-column: -1 / 1;
}

.lrt-container > h4 {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 4px;
  grid-column: -1 / 1;
}

.lrt-container > p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  text-transform: none;
  font-size: medium;
  font-weight: 200;
  max-width: 500px;
  margin-top: -2rem;
  grid-column: -1 / 1;
}

.lrt-container .lrt-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  row-gap: 2rem;
  margin-bottom: 3rem;
}

.lrt-container .lrt-highlights-item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 30px auto;
  align-items: center;
}

.lrt-container .lrt-highlights-item img {
  max-width: 100%;
}

.lrt-container .lrt-highlights-item p {
  font-family: "Inter", sans-serif;
  line-height: 25px;
}

.lrt-container .lrt-highlights-item p + p {
  font-weight: 900;
  letter-spacing: 1px;
}

.lrt-container .lrt-highlights-chart {
  display: grid;
  row-gap: 1.5rem;
}

.lrt-container .lrt-age-pie-chart {
  margin-top: 2rem;
}

.lrt-note {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

.lrt-container > p.lrt-photosource {
  font-size: 10px;
  position: relative;
  left: 50px;
  top: 100px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media (min-width: 1024px) {
  .lrt-container > * {
    grid-column: auto;
  }
}

@media (max-width: 1024px) {
  .lrt-container {
    display: none;
  }
}
</style>
