<template>
  <div class="consumer-container">
    <div data-aos="fade-right" data-aos-duration="3000" class="wrapper">
      <div class="consumer-bubble-container">
        <h2>
          Konsumen Indonesia <br />
          Didominasi oleh <br />
          Gen Z dan Milenial
        </h2>
        <p class="content-1">
          Indonesia menjadi negara yang didominasi oleh Milenial dan Gen Z.
          Berdasarkan data
          <a href="https://sensus.bps.go.id/main/index/sp2020">Sensus Penduduk Indonesia <sup>6</sup>,</a>
        </p>
        <p class="content-2">
          71 juta penduduk Indonesia didominasi oleh Gen Z dan 69 juta
          didominasi oleh milenial.
        </p>
      </div>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="chart-area">
      <ConsumerBarChart />
      <p>Photo Source: Unsplash</p>
    </div>
  </div>
</template>

<script>
import ConsumerBarChart from "./ConsumerBarChart.vue";

export default {
  name: "consumer-container",
  components: {
    ConsumerBarChart,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
      chartOptions: {
        colors: ["#00E2F4", "#F2ADFF"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "bar",
          height: 430,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 6,
            borderRadiusApplication: "end",
            barHeight: 16,
          },
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: ["#00E2F4", "#F2ADFF"],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 4,
          colors: ["transparent"],
        },
        xaxis: {
          tickAmount: 3,
          categories: [
            "Pre Boomer",
            "Boomer",
            "Generasi X",
            "Milenial",
            "Generasi Z",
            "Post Generasi Z",
          ],
          labels: {
            formatter: (value) => value.toLocaleString("id-ID"),
            style: {
              colors: ["#FFFFFF"],
            },
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: ["#FFFFFF"],
            },
          },
        },
      },
      series: [
        {
          name: "Laki - laki",
          data: [6000000, 15000000, 28000000, 34000000, 35000000, 18000000],
        },
        {
          name: "Perempuan",
          data: [7000000, 15500000, 28000000, 33000000, 33000000, 17000000],
        },
      ],
    };
  },
};
</script>

<style>
.consumer-container {
  color: #ffffff;
  min-height: calc(100svh - 80px);
  background: url("~@/assets/annual-report-2024/bg_consumer.jpg");
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.consumer-container .wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
  grid-column-start: 1;
  grid-column-end: 3;
}

.consumer-container .consumer-bubble-container {
  height: 550px;
  width: 550px;
  margin-left: -55px;
  background-color: #233ae0;
  border-radius: 100%;
  padding-left: 120px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.consumer-container .consumer-bubble-container h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-size: 2rem;
  margin-bottom: 20px;
  padding-top: 0px;
}

.consumer-container .consumer-bubble-container .content-1 {
  font-size: 1rem;
  margin-bottom: 10px;
  padding-left: 0px;
  line-height: 1.4;
}

.consumer-container .consumer-bubble-container .content-2 {
  font-size: 1.4rem;
  font-weight: bold;
  padding-left: 0px;
  line-height: 1.4;
}

.consumer-container .chart-area {
  padding-right: 200px;
  grid-column-start: 3;
  grid-column-end: 7;
}

.content-1 sup {
  font-size: 8px;
}

.content-1 a {
  color: #ffffff;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.content-1, .content-2 {
  font-family: "Inter", sans-serif;
}

.consumer-container .chart-area p {
  font-size: 10px;
  position: absolute;
  right: 8px;
}

@media only screen and (max-width: 1024px) {
  .consumer-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .consumer-container .consumer-bubble-container {
    width: 500px;
    height: 500px;
  }
  .consumer-container .consumer-bubble-container h2 {
    font-size: 1.4rem;
  }
}
</style>
