<template>
    <div class="transport-container">
        <div data-aos="flip-right" data-aos-duration="2000" class="bubble-container">
            <p>Tak hanya memahami keinginan</p>
            <p>generasi Z dan millenial dalam memilih</p>
            <p>tempat nongkrong,</p>

            <br />

            <div class="bold-content">
                <p>kami juga mengumpulkan data</p>
                <p>untuk memahami kebiasaan </p>
                <p>mereka dalam menggunakan</p>
                <p>transportasi umum.</p>
            </div>
        </div>
        <p class="transport-photosource">Photo Source: Unsplash</p>
    </div>
</template>

<script>
    export default {
        name: 'transport-container',
        data: function () {
        return {
        windowWidth: 0,
        windowHeight: 0,
        lang: ''
        }
    },
}
</script>

<style>
.transport-container {
    color: #ffffff;
    background-image: url(~@/assets/annual-report-2024/yearly_bg_transhabit.jpg);
    background-size: cover;
    min-height: calc(100svh - 80px);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.bubble-container p {
  font-size: 1.3rem !important;
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  padding-right: 0px;
  padding-left: 0px;
}

.bubble-container {
    height: 550px;
    width: 550px;
    margin-left: -55px;
    background-color: #233ae0;
    border-radius: 100%;
    margin-top: 100px;
    padding-left: 120px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bold-content {
    margin-top: 30px;
}

.bold-content p {
    font-size: 1.5rem !important;
    font-weight: 900;
}

.transport-photosource {
  font-size: 10px;
  position: relative;
  left: 50px;
  top: 20px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media only screen and (max-width: 1024px) {
  .transport-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .bold-content p {
    font-size: 1.4rem !important;
    font-weight: 900;
  }

  .bubble-container p {
    font-size: 1.2rem !important;
  }
}
</style>
