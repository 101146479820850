<template>
    <div class="firstfootprint-container">
        <div class="footprint-desc-wrapper">
            <div></div>
            <div class="footprint-desc">
                <p>Tak berhenti sampai di situ, <span>kami juga mengumpulkan data untuk mengetahui digital</span></p>
                <p><span>footprint pengguna transportasi publik.</span> Kami mengambil dua sampel untuk </p>
                <p>mengetahui serangkaian aktivitas atau tindakan yang dilakukan para pengguna.</p>
            </div>
        </div>
        <div class="footprint-wrapper">
            <div></div>
            <div>
                <p>Tempat yang Sering Dikunjungi</p>
                <div class="footprint-highlights">
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'firstfootprint-container',
        data: function () {
            return {
                windowWidth: 0,
                windowHeight: 0,
                lang: '',
            };
        },
    };
</script>

<style>
.firstfootprint-container {
    background-color: #4b00be;
    color: #ffffff;
    min-height: calc(100svh - 80px);
}

.footprint-desc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background-color: #CDF9FD;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.footprint-desc {
    background-color: #CDF9FD;
    color: #000000;
    padding-right: 180px;
}

.footprint-wrapper {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.footprint-wrapper > :nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;
    justify-content: center;
}

.footprint-wrapper > :nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 4;
    align-items: center;
    justify-content: center;
}

</style>
