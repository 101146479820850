<template>
  <div>
    <div style="background-color: #104ff5">
      <section class="container">
        <div class="header">
          <h2 data-aos="fade-down" data-aos-duration="1500" class="header_text">
            Tak berhenti sampai di situ,
            <strong
              >kami juga mencoba memahami pola lifestyle Gen Z dan milenial
              dalam menggunakan transportasi publik dan pergi ke coffee shop
              atau resto.</strong
            >
          </h2>
        </div>
        <div class="containerimage">
          <img
            data-aos="fade-right"
            data-aos-duration="1800"
            class="image1"
            src="../../assets/annual-report-2024/section19-1.png"
            alt="section19"
          />
          <img
            data-aos="fade-left"
            data-aos-duration="1800"
            class="image2"
            src="../../assets/annual-report-2024/section19-2.png"
            alt="section19"
          />
        </div>
        <p class="genz-photosource">Photo Source: Unsplash</p>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: "journey-web",
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 67.5rem;
  position: relative;
  max-width: 1980px;
  margin: auto;
}
.header {
  width: 100%;
  height: 14.188rem;
  background-color: #cdf9fd;
  display: flex;
  justify-content: end;
  align-items: center;
}
.header_text {
  max-width: 900px;
  color: #253be2;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding-right: 10px;
}
.containerimage {
  display: inline;
}
.container .image1 {
  max-width: 60%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.container .containerimage .image2 {
  height: 70%;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.genz-photosource {
  font-size: 10px;
  position: relative;
  left: 50px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .container {
    height: 750px;
  }
  .header_text {
    max-width: 750px;
  }
  .container .containerimage .image2 {
    height: 52%;
    right: 8px;
    bottom: 68px;
  }
}
</style>
