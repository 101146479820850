<template>
  <div class="digital-container">
    <div data-aos="fade-right" data-aos-duration="3000" class="content-1">
      <p>
        Menjadi generasi yang tumbuh dengan<br />
        internet, tak heran membuat Gen Z<br />
        mendapat julukan sebagai
        <a href="https://www.mckinsey.com/featured-insights/mckinsey-explainers/what-is-gen-z">“Digital<br />
          Natives”<sup>7</sup></a>. Mereka terbiasa untuk mencari<br />
        informasi apa pun, termasuk berita hingga<br />
        ulasan sebelum melakukan pembelian.
      </p>

      <br />

      <p>Tidak berbeda dengan Gen Z, Milenial<br />
        juga disebut sebagai generasi
        <a href="https://www2.deloitte.com/content/dam/insights/us/articles/4511_Millenials-and-beyond/DI_DR24_Inbrief_Millennial-behavior.pdf">
          “Pencari<br />
          Pengalaman”</a>.
      </p>

      <br />

      <p>
        Generasi milenial rela mengeluarkan<br />
        uang untuk pengalaman, seperti<br />
        pengalaman untuk mendapatkan hiburan<br />
        hingga makan di luar. Tak heran, <span>rata-<br />
        rata jarak mereka kembali ke coffee shop<br />
        yaitu 3 hari hingga 4 hari.</span>
      </p>
    </div>
    <img
      data-aos="fade-down" data-aos-duration="3000"
      class="genz-image"
      src="~@/assets/annual-report-2024/bg_digital_genz.png"
    />
    <div data-aos="fade-left" data-aos-duration="5000">
      <div class="content-2">
        <p>
          Sering disebut sebagai generasi yang<br />
          gemar belanja, nyatanya<br />
          <br />
          <span>57% Gen Z</span><br />
          <span>50% Milenial</span><br />
          <br />

          Mempunyai top of mind untuk
          <a href="https://blog.hubspot.com/marketing/how-each-generation-shops-differently#genz">
          melihat produk<br />
          baru di media sosial<sup>9</sup></a>sebelum melakukan<br />
          pembelian. Media sosial yang dilihat<br />
          mencakupi Instagram, TikTok, Facebook, dan<br>
          juga Review dari orang lainnya.
        </p>
      </div>
      <div class="content-3">
        <p>
          Baik Generasi Z dan milenial, keduanya memiliki<br />
          kesamaan yaitu tidak bisa lepas dari internet<br />
          dan suka mencari pengalaman. Tapi apakah<br />
          pilihan tempat nongkrong mereka sama?
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digital-container",
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
};
</script>

<style>
.digital-container {
  background-color: #233ae0;
  color: #ffffff;
  padding-top: 2rem;
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: 1fr 0 1fr;
  align-items: center;
}

.digital-container .genz-image {
  max-width: 500px;
  place-self: end center;
  margin-left: 150px;
  z-index: 1;
}

.digital-container .content-1 {
    margin-top: -100px;
    background-image: url(~@/assets/annual-report-2024/bg_purples.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 12% 50%;
    padding-top: 40px;
    padding-left: 110px;
    position: relative;
    margin-left: 30px;
}

.digital-container .content-1::after {
  content: "";
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.digital-container .content-1 p {
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  line-height: 1.4;
}

.digital-container a {
  color: white;
}

.digital-container .content-2 {
  background-color: #5400d2;
  height: 420px;
  width: 420px;
  border-radius: 100%;
  margin-left: 350px;
  padding-top: 108px;
  padding-left: 80px;
}

.digital-container .content-2 p {
  font-size: 1rem;
  z-index: 3;
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.digital-container .content-2 p + p {
  margin-top: 20px;
  line-height: 1.4;
}

.digital-container .content-3 {
  margin-left: 438px;
  margin-top: 50px;
}

.digital-container .content-3 {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.digital-container .content-1 a, .content-2 a {
    color: #ffffff;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}

.digital-container p {
  padding-left: 0px;
  padding-right: 0px;
}

.digital-container .content-1 a,
.digital-container .content-1 p span {
  font-weight: 900;
}

@media only screen and (max-width: 1024px) {
  .digital-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .digital-container .content-1 {
    background-position: 5% 100%;
    padding-left: 35px;
    margin-left: 10px;
  }

  .digital-container .genz-image {
    max-width: 340px;
    margin-left: 268px;
  }

  .digital-container .content-2  {
    height: 420px;
    width: 420px;
    margin-left: 225px;
    padding-top: 75px;
  }

  .digital-container .content-2 p {
    font-size: 0.85rem;
  }

  .digital-container .content-2 p span {
    font-size: 1.1rem;
    font-weight: 900;
  }

  .digital-container .content-3 {
    margin-left: 310px;
  }

  .digital-container .content-3 p {
    font-size: 0.8rem;
  }

  .digital-container .content-1 a,
  .digital-container .content-1 p span {
    font-weight: 900;
  }
}

@media only screen and (min-width: 1271px) and (max-width: 1300px)  {
  .digital-container .genz-image{
    max-width: 450px;
    place-self: end center;
    margin-left: 300px;
    z-index: 1;
  }
}
</style>
