<template>
    <div class="asdp-container">
      <h4 data-aos="fade-down" data-aos-duration="3000">ASDP</h4>
      <div data-aos="fade-right" data-aos-duration="3000">
        <div class="asdp-highlights">
          <div class="asdp-highlights-item">
            <img
              class="asdp-icon"
              src="@/assets/annual-report-2024/calendar_icon.png"
              alt="asdp-rush-month"
            />
            <div>
              <p>Bulan paling ramai</p>
              <p>Maret - April & Juni - Juli</p>
            </div>
          </div>
          <div class="asdp-highlights-item">
            <img
              src="@/assets/annual-report-2024/calendar_icon.png"
              alt="asdp-rush-day"
            />
            <div>
              <p>Hari paling ramai</p>
              <p>Senin</p>
            </div>
          </div>
          <div class="asdp-highlights-item">
            <img
              src="@/assets/annual-report-2024/clock_icon.png"
              alt="asdp-rush-hour"
            />
            <div>
              <p>Jam paling ramai</p>
              <p>5 - 6 Sore</p>
            </div>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="3000" class="asdp-highlights-chart">
          <div class="asdp-highlights-item">
            <img
              src="@/assets/annual-report-2024/visitor_icon.png"
              alt="asdp-visitor-gender"
            />
            <p>Gender Pengguna</p>
          </div>
          <AsdpGenderBarChart />
        </div>
      </div>
      <div class="asdp-highlights-chart">
        <div class="asdp-highlights-item">
          <img
            src="@/assets/annual-report-2024/visitor_icon.png"
            alt="asdp-visitor-age"
          />
          <p>Usia Pengguna</p>
        </div>
        <div data-aos="fade-right" data-aos-duration="3000" class="asdp-age-pie-chart">
            <AgePieChart
                :under18="5.59"
                :between18And24="23.23"
                :between25And34="31.83"
                :between35And44="24.05"
                :between45And54="9.55"
                :between55And64="4.78"
                :over64="0.97"
            />
            <div class="asdp-chart-shortdesc">
                <p><span>Lebih dari 50% penumpang ASDP adalah milenial dan Gen Z</span></p>
                <p>Di tahun 2023, kenaikan traffic yang terjadi di bulan Maret hingga April<br />
                dikarenakan bulan Ramadhan dan Idul Fitri. Sedangkan di bulan Juni hingga<br />
                Juli, kenaikan disebabkan oleh libur semester genap sekolah.
                </p>
                <br />
                <p class="asdp-location-note">*Lokasi ASDP yang digunakan sebagai sampel terdiri dari Cilegon, Kupang, Ternate, Lampung Selatan</p>
            </div>
        </div>
      </div>
      <p class="asdp-photosource">Photo Source: https://images.app.goo.gl/773M3bkyXLRQczNQ7</p>
    </div>
  </template>

<script>
import AgePieChart from './AgePieChart.vue';
import AsdpGenderBarChart from './AsdpGenderBarChart.vue'

    export default {
        name: 'asdp-container',
        components: { AgePieChart, AsdpGenderBarChart }
    };
</script>

<style>
.asdp-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(~@/assets/annual-report-2024/bg_asdp.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  row-gap: 3rem;
  align-items: baseline;
}

.asdp-container > * {
  grid-column: -1 / 1;
}

.asdp-container > h4 {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 4px;
  grid-column: -1 / 1;
}

.asdp-container > p {
  text-transform: none;
  font-size: medium;
  font-weight: 200;
  max-width: 500px;
  margin-top: -2rem;
  grid-column: -1 / 1;
}

.asdp-container .asdp-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  row-gap: 2rem;
  margin-bottom: 3rem;
}

.asdp-container .asdp-highlights-item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 30px auto;
  align-items: center;
}

.asdp-container .asdp-highlights-item img {
  max-width: 100%;
}

.asdp-container .asdp-highlights-item p {
  font-family: "Inter", sans-serif;
  line-height: 25px;
}

.asdp-container .asdp-highlights-item p + p {
  font-weight: 900;
  letter-spacing: 1px;
}

.asdp-container .asdp-highlights-chart {
  display: grid;
  row-gap: 1.5rem;
}

.asdp-container .asdp-age-pie-chart {
  margin-top: 2rem;
}

.asdp-chart-shortdesc {
    margin-top: 40px;
    margin-left: 35px;
}

.asdp-chart-shortdesc p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.asdp-chart-shortdesc p span {
  font-weight: 900;
}

.asdp-chart-shortdesc p:nth-child(1) {
    margin-bottom: 20px;
}

.asdp-chart-shortdesc .asdp-location-note {
  font-size: 12px;
}

.asdp-photosource {
  font-size: 10px !important;
  position: relative;
  left: 50px;
  top: 100px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media only screen and (min-width: 1271px) {
  .asdp-container > * {
    grid-column: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .asdp-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .asdp-container > .asdp-highlights-chart {
    grid-column: span 2;
  }

  .asdp-container > .asdp-age-pie-chart {
    grid-column: span 2;
  }
}
</style>
