<template>
  <div class="additional-container">
    <div class="additional-wrapper">
      <div class="additional-desc-wrapper">
        <p>
          Sebagai generasi yang mencakup sebagian besar penduduk Indonesia dan
          memiliki<br />
          pengaruh yang besar dalam pengambilan keputusan pembelian, kami yakin
          dengan<br />
          memahami Gen Z dan milenial, setiap bisnis dapat merancang strategi
          pemasaran <br />
          yang lebih efektif. Sehingga dengan hasil penelitian ini, dapat
          menjadi insight<br />
          tambahan dalam membuat strategi marketing yang tepat di tahun 2024.<br />

          <br />

          HIGO adalah WiFi Technology & Integrated Digital Agency, dimana produk
          yang kami<br />
          berikan mulai dari WiFi Ads, Digital Advertising, Social Media
          Management, KOL<br />
          Management, Creative Production, Offline & Online Branding, hingga 360
          Campaign.<br />
          Kami mampu membantu bisnis dalam melakukan strategi marketing dari
          online hingga<br />
          offline, sehingga dapat membangun hubungan yang kuat dengan semua
          audiens,<br />
          khususnya Gen Z dan milenial.
        </p>
      </div>
      <div class="contactus-wrapper">
        <p>Informasi lengkapnya bisa menghubungi kami di:</p>
        <div class="contactus-list">
          <ul>
            <li><a href="mailto:info@higo.id">info@higo.id</a></li>
            <li><a href="https://higo.id/">www.higo.id</a></li>
            <li>0817-0753-544</li>
          </ul>
        </div>
      </div>
      <div class="source-wrapper">
        <p>Sumber:</p>
        <ol>
          <li>
            <a
              href="https://en.antaranews.com/news/278436/food-beverage-industry-predicted-to-grow-7-ministry"
            >
              https://en.antaranews.com/news/278436/food-beverage-industry-predicted-to-grow-7-ministry</a
            >
          </li>
          <li>
            <a
              href="https://news.detik.com/berita/d-6773611/pertama-sejak-pandemi-penumpang-transjakarta-capai-1-juta-per-hari"
            >
              https://www.google.com/url?q=https://news.detik.com/berita/d-6773611/pertama-sejak-pandemi-penumpang-transjakarta-capai-1-juta-per-hari&sa=D&source=docs&ust=1703670478529929&usg=AOvVaw10l32pgGKjQ9r-v0_ic3wf</a
            >
          </li>
          <li>
            <a href="https://survei.apjii.or.id/survei/">
              https://www.google.com/url?q=https://survei.apjii.or.id/survei/&sa=D&source=docs&ust=1703670503613177&usg=AOvVaw1r54UWI3pVgRtArYDGCRKO</a
            >
          </li>
          <li>
            <a
              href="https://www.mckinsey.com/industries/consumer-packaged-goods/our-insights/meet-generation-z-shaping-the-future-of-shopping"
            >
              https://www.mckinsey.com/industries/consumer-packaged-goods/our-insights/meet-generation-z-shaping-the-future-of-shopping</a
            >
          </li>
          <li>
            <a href="https://sensus.bps.go.id/main/index/sp2020"
              >https://sensus.bps.go.id/main/index/sp2020</a
            >
          </li>
          <li>
            <a
              href="https://www.mckinsey.com/featured-insights/mckinsey-explainers/what-is-gen-z"
            >
              https://www.mckinsey.com/featured-insights/mckinsey-explainers/what-is-gen-z</a
            >
          </li>
          <li>
            <a
              href="https://www2.deloitte.com/content/dam/insights/us/articles/4511_Millenials-and-beyond/DI_DR24_Inbrief_Millennial-behavior.pdf"
            >
              https://www2.deloitte.com/content/dam/insights/us/articles/4511_Millenials-and-beyond/DI_DR24_Inbrief_Millennial-behavior.pdf</a
            >
          </li>
          <li>
            <a
              href="https://blog.hubspot.com/marketing/how-each-generation-shops-differently#genz"
            >
              https://blog.hubspot.com/marketing/how-each-generation-shops-differently#genz</a
            >
          </li>
          <li>
            <a
              href="https://www.nielsen.com/insights/2021/beyond-martech-building-trust-with-consumers-and-engaging-where-sentiment-is-high/"
            >
              https://www.nielsen.com/insights/2021/beyond-martech-building-trust-with-consumers-and-engaging-where-sentiment-is-high/
            </a>
          </li>
          <li>
            <a href="https://www.semrush.com/blog/word-of-mouth-stats/"
              >https://www.semrush.com/blog/word-of-mouth-stats/</a
            >
          </li>
          <li>
            <a
              href="https://www.antaranews.com/berita/2187938/alasan-orang-indonesia-gemar-gunakan-layanan-prabayar"
            >
              https://www.antaranews.com/berita/2187938/alasan-orang-indonesia-gemar-gunakan-layanan-prabayar</a
            >
          </li>
        </ol>
      </div>
    </div>
    <div>
      <AnnualReportForm />
    </div>
  </div>
</template>

<script>
import AnnualReportForm from "./AnnualReportForm";

export default {
  name: "additional-container",
  components: {
    AnnualReportForm,
  },
};
</script>

<style scoped>
.additional-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.additional-wrapper {
  padding: 10px;
}

.additional-container > :nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  margin: 32px 20px 0px 20px;
}

.additional-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  background-color: #253be3;
}

.contactus-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactus-list ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
}

.contactus-list ul li {
  font-weight: 900;
  margin-top: 20px;
  margin-right: 30px;
}

.contactus-list ul li {
  text-decoration: underline;
}

.contactus-list ul li:last-of-type {
  text-decoration: none;
}

.source-wrapper {
  margin-top: 40px;
}

.source-wrapper ol {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 13px;
}

.source-wrapper ol li {
  margin-bottom: 12px;
}

@media only screen and (max-width: 1024px) {
  .additional-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .additional-container {
    grid-template-columns: 1fr;
  }

  .additional-container > :nth-child(1) {
    grid-column: 1;
    margin: 32px 20px 20px 20px;
  }

  .additional-container > :nth-child(2) {
    grid-column: 1;
  }
}
</style>
