<template>
  <div class="post-paid-provider-pie-chart">
    <ApexChart
      type="pie"
      width="667px"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "post-paid-provider-piechart",
  props: {
    telkomsel: Number,
    indosat: Number,
    xl: Number,
    smartfren: Number,
  },
  data: function () {
    return {
      chartOptions: {
        labels: ["Telkomsel", "Indosat Ooredoo", "XL", "Smart Fren"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "12px",
          fontFamily: "Inter, Arial",
          offsetY: 20,
          offsetX: -100,
          position: "right",
          horizontalAlign: "center",
          width: 200,
          height: 400,
          markers: {
            width: 18,
            height: 18,
            radius: 9,
            offsetX: -5,
            offsetY: 3,
            fillColors: ["#FF7D90", "#F3E4AC", "#577FFF", "#FFBAD0"],
          },
          itemMargin: {
            vertical: 12,
          },
          labels: {
            colors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
          },
          formatter: function (label, opts) {
            const percentage = opts.w.config.series[opts.seriesIndex];
            return `<div>${label}</div>
                            <div class="piechart-legend-label">${percentage.toFixed(
                              1
                            )}%
                            </div>
                            `;
          },
        },

        fill: {
          colors: ["#FF7D90", "#F3E4AC", "#577FFF", "#FFBAD0"],
        },
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 1512,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                offsetY: -100,
                offsetX: -20,
                itemMargin: {
                  vertical: 10,
                },
              },
            },
          },
        ],
      },
      series: [this.telkomsel, this.indosat, this.xl, this.smartfren],
    };
  },
};
</script>

<style>
.post-paid-provider-pie-chart .apexcharts-legend-series {
  display: flex;
}
.post-paid-provider-pie-chart .apexcharts-legend {
  top: 0;
}

.post-paid-provider-pie-chart .piechart-legend-label {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
}

.post-paid-provider-pie-chart .apexcharts-legend-text *:nth-child(1) {
  font-size: 20px;
  font-weight: normal;
  margin-top: 3px;
  opacity: 0.8;
}

.post-paid-provider-pie-chart .apexcharts-canvas svg {
  overflow: visible;
}

.post-paid-provider-pie-chart .apexcharts-canvas svg foreignObject {
  overflow: visible;
}
</style>
