<template>
    <div class="secondfootprint-container">
        <div class="secondfootprint-wrapper">
            <div>
                <p>Tempat yang Sering Dikunjungi</p>
                <div class="footprint-highlights">
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                    <div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
        <div class="secondprint-desc-wrapper">
            <div></div>
            <div class="secondfootprint-desc">
                <p><span>Mengetahui digital footprint menjadi aspek penting dalam</span></p>
                <p><span>merancang strategi pemasaran yang efektif dan efisien</span> </p>
                <p>Sehingga semua strategi pemasaran yang dilakukan bisa tepat sasaran.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'secondfootprint-container',
        data: function () {
            return {
                windowWidth: 0,
                windowHeight: 0,
                lang: '',
            };
        },
    };
</script>

<style>
.secondfootprint-container {
    background-color: #7862FF;
    color: #ffffff;
    min-height: calc(100svh - 80px);
}

.secondprint-desc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #CDF9FD;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.secondfootprint-desc {
    background-color: #CDF9FD;
    color: #000000;
    padding-left: 4rem;
}

.secondfootprint-wrapper {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.secondfootprint-wrapper > :nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 2;
    justify-content:flex-start;
}

.secondfootprint-wrapper > :nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 4;
    align-items: center;
    justify-content: center;
}
</style>
