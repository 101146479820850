<template>
  <ApexChart
    width="100%"
    height="80%"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "most-used-internet-provider-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#04E3F4"],
        chart: {
          background: "transparent",
          fontFamily: "Inter, Arial",
          foreColor: "#fff",
          type: "bar",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 25,
            borderRadiusApplication: "end",
            columnWidth: 56,
            fontFamily: "Inter, Arial",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
          fontFamily: "Inter, Arial",
          itemMargin: {
            vertical: 30,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          formatter: (value) => `${value.toFixed(2)}%`,
        },
        fill: {
          colors: ["#04E3F4"],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 6,
          colors: ["transparent"],
        },
        xaxis: {
          tickAmount: 3,
          categories: [
            ["<5 Mbps"],
            "5-10 Mbps",
            "11-20 Mbps",
            "21-50 Mbps",
            ">50 Mbps",
          ],
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: 16,
            },
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: "Internet",
          data: [2.05, 46.67, 32.82, 15.38, 3.08],
        },
      ],
    };
  },
};
</script>
