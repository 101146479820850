<template>
  <div class="annual-container">
    <!-- navigation -->
    <Navbar @lang="getLang" color="white" hide-on-scroll-down="true" />

    <AnnualHero />
    <LocationSample />
    <Consumer />
    <DigitalNatives />
    <HangoutSpot />
    <ImportantFactors />
    <MarketingTechnic />
    <SocialMediaFavGenZ />
    <CoffeeOrder />
    <CoffeeFav />
    <PositiveFeedback />
    <TopTenCofShop />
    <NegativeFeedback />
    <TransportHabit />
    <Transjakarta />
    <Mrt />
    <Lrt />
    <WaterTransportation />
    <Asdp />
    <Pelindo />
    <!--<FirstFootprint />-->
    <!--<SecondFootprint />-->

    <JourneyWeb />
    <GenZHabit />
    <PostPaidProvider />
    <MostUsedInternetProvider />
    <InternetByArea />
    <AnalysisPattern />
    <!-- <BusyHour /> -->
    <TrendConsumen />
    <FavoriteFoodOrderApp />
    <MethodPayment />
    <AdditionalInsight />
    <!-- Footer  -->
    <Footer />
  </div>
</template>

<script>
import AdditionalInsight from "@/components/AnnualReport2024/AdditionalInsight";
import AnnualHero from "@/components/AnnualReport2024/AnnualHero";
import Asdp from "@/components/AnnualReport2024/Asdp";
import CoffeeFav from "@/components/AnnualReport2024/CoffeeFav";
import CoffeeOrder from "@/components/AnnualReport2024/CoffeeOrder";
import Consumer from "@/components/AnnualReport2024/Consumer";
import DigitalNatives from "@/components/AnnualReport2024/DigitalNatives";
import FirstFootprint from "@/components/AnnualReport2024/FirstFootprint";
import HangoutSpot from "@/components/AnnualReport2024/HangoutSpot";
import ImportantFactors from "@/components/AnnualReport2024/ImportantFactors";
import LocationSample from "@/components/AnnualReport2024/LocationSample";
import Lrt from "@/components/AnnualReport2024/Lrt";
import MarketingTechnic from "@/components/AnnualReport2024/MarketingTechnic";
import Mrt from "@/components/AnnualReport2024/Mrt";
import NegativeFeedback from "@/components/AnnualReport2024/NegativeFeedback";
import Pelindo from "@/components/AnnualReport2024/Pelindo";
import PositiveFeedback from "@/components/AnnualReport2024/PositiveFeedback";
import SecondFootprint from "@/components/AnnualReport2024/SecondFootprint";
import TopTenCofShop from "@/components/AnnualReport2024/TopTenCofShop";
import Transjakarta from "@/components/AnnualReport2024/Transjakarta";
import TransportHabit from "@/components/AnnualReport2024/TransportHabit";
import WaterTransportation from "@/components/AnnualReport2024/WaterTransportation";
import SocialMediaFavGenZ from "@/components/AnnualReport2024/SocialMediaFavGenZ.vue";
import JourneyWeb from "@/components/AnnualReport2024/JourneyWeb.vue";
import GenZHabit from "@/components/AnnualReport2024/GenZHabit.vue";
import PostPaidProvider from "@/components/AnnualReport2024/PostPaidProvider.vue";
import FavoriteFoodOrderApp from "@/components/AnnualReport2024/FavoriteFoodOrderApp.vue";
import MethodPayment from "@/components/AnnualReport2024/MethodPayment.vue";
import TrendConsumen from "@/components/AnnualReport2024/TrendConsumen.vue";
import MostUsedInternetProvider from "@/components/AnnualReport2024/MostUsedInternetProvider.vue";
import InternetByArea from "@/components/AnnualReport2024/InternetByArea.vue";
// import BusyHour from "@/components/AnnualReport2024/BusyHour.vue";
import AnalysisPattern from "@/components/AnnualReport2024/AnalysisPattern.vue";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar,
    Footer,
    AnnualHero,
    AdditionalInsight,
    Asdp,
    CoffeeFav,
    CoffeeOrder,
    Consumer,
    DigitalNatives,
    FirstFootprint,
    SecondFootprint,
    HangoutSpot,
    ImportantFactors,
    LocationSample,
    Lrt,
    MarketingTechnic,
    Mrt,
    NegativeFeedback,
    Pelindo,
    PositiveFeedback,
    TopTenCofShop,
    Transjakarta,
    TransportHabit,
    WaterTransportation,
    SocialMediaFavGenZ,
    JourneyWeb,
    GenZHabit,
    PostPaidProvider,
    TrendConsumen,
    FavoriteFoodOrderApp,
    MethodPayment,
    InternetByArea,
    MostUsedInternetProvider,
    AnalysisPattern,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
  metaInfo: {
    title: "Annual Report - HIGO Digital Industri 2024",
    meta: [
      {
        name: "description",
        content:
          "Inovasi digital membentuk trend bisnis f&b di Indonesia, karena mampu memahami perilaku generasi Z dan milenial secara lebih mendalam.",
      },
      {
        name: "keywords",
        content:
          "Annual report, HIGO, Higo digital industry, Tren bisnis, Bisnis f&b, Generasi Z, Generasi milenial,  Memahami perilaku gen z dan milenial",
      },
    ],
  },
};
</script>

<style>
.annual-container > * {
  overflow-x: clip;
}

.grid {
  --padding-inline: 4rem;
  --content-max-width: 1400px;
  --breakout-max-width: 1600px;
  --breakout-size: calc(
    (var(--breakout-max-width) - var(--content-max-width)) / 2
  );
  --content-size: calc(100% - (var(--padding-inline) * 2));

  display: grid;
  grid-template-columns:
    [full-start]
    minmax(var(--padding-inline), 1fr)
    [breakout-start]
    minmax(0, var(--breakout-size))
    [content-start]
    min(var(--content-size), var(--content-max-width))
    [content-end]
    minmax(0, var(--breakout-size))
    [breakout-end]
    minmax(var(--padding-inline), 1fr)
    [full-end];
}

.grid > * {
  grid-column: content;
}

.grid .grid-full {
  grid-column: full;
}

.grid .grid-content {
  grid-column: content;
}

.grid .grid-breakout {
  grid-column: breakout;
}
</style>
