<template>
  <div class="transjakarta-container">
    <h4 data-aos="fade-down" data-aos-duration="3000">Transjakarta</h4>
    <p data-aos="fade-down" data-aos-duration="3000">
      Dari total 9 koridor dan 166 halte Transjakarta yang ada di Jakarta Barat,
      Jakarta Pusat, Jakarta Selatan, Jakarta Timur, dan Tangerang, kami
      menemukan kebiasaan yang ada di Transjakarta sebagai berikut.
    </p>
    <div data-aos="fade-right" data-aos-duration="3000">
      <div class="transjakarta-highlights">
        <div class="transjakarta-highlights-item">
          <img
            class="transjakarta-icon"
            src="@/assets/annual-report-2024/calendar_icon.png"
            alt="transjakarta-rush-month"
          />
          <div>
            <p>Bulan paling ramai</p>
            <p>September</p>
          </div>
        </div>
        <div class="transjakarta-highlights-item">
          <img
            src="@/assets/annual-report-2024/calendar_icon.png"
            alt="transjakarta-rush-day"
          />
          <div>
            <p>Hari paling ramai</p>
            <p>Jumat</p>
          </div>
        </div>
        <div class="transjakarta-highlights-item">
          <img
            src="@/assets/annual-report-2024/clock_icon.png"
            alt="transjakarta-rush-hour"
          />
          <div>
            <p>Jam paling ramai</p>
            <p>5 - 6 Sore</p>
          </div>
        </div>
      </div>
      <div class="transjakarta-highlights-chart">
        <div class="transjakarta-highlights-item">
          <img
            src="@/assets/annual-report-2024/visitor_icon.png"
            alt="transjakarta-visitor-gender"
          />
          <p>Gender Pengguna</p>
        </div>
        <TransjakartaGenderBarChart :priaPercentage="64.18" :wanitaPercentage="35.82" />
      </div>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="transjakarta-highlights-chart">
      <div class="transjakarta-highlights-item">
        <img
          src="@/assets/annual-report-2024/visitor_icon.png"
          alt="transjakarta-visitor-age"
        />
        <p>Usia Pengguna</p>
      </div>
      <div class="transjakarta-age-pie-chart">
        <AgePieChart
          :under18="5.0"
          :between18And24="21.27"
          :between25And34="34.83"
          :between35And44="21.71"
          :between45And54="12.43"
          :between55And64="3.49"
          :over64="0.55"
        />
      </div>
      <p class="transjakarta-note">Lebih dari 55% penumpang Transjakarta adalah Gen Z dan milenial.</p>
    </div>
    <p class="transjakarta-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import TransjakartaGenderBarChart from "./TransjakartaGenderBarChart.vue";
import AgePieChart from "./AgePieChart.vue";

export default {
  name: "transjakarta-container",
  components: { TransjakartaGenderBarChart, AgePieChart },
};
</script>

<style>
.transjakarta-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(~@/assets/annual-report-2024/bg_transjakarta.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  row-gap: 3rem;
  align-items: baseline;
}

.transjakarta-container > * {
  grid-column: -1 / 1;
}

.transjakarta-container > h4 {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 4px;
  grid-column: -1 / 1;
}

.transjakarta-container > p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  text-transform: none;
  font-size: medium;
  font-weight: 200;
  max-width: 500px;
  margin-top: -2rem;
  grid-column: -1 / 1;
}

.transjakarta-container .transjakarta-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  row-gap: 2rem;
  margin-bottom: 3rem;
}

.transjakarta-container .transjakarta-highlights-item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 30px auto;
  align-items: center;
}

.transjakarta-container .transjakarta-highlights-item img {
  max-width: 100%;
}

.transjakarta-container .transjakarta-highlights-item p {
  font-family: "Inter", sans-serif;
  line-height: 25px;
}

.transjakarta-container .transjakarta-highlights-item p + p {
  font-weight: 900;
  letter-spacing: 1px;
}

.transjakarta-container .transjakarta-highlights-chart {
  display: grid;
  row-gap: 1.5rem;
}

.transjakarta-container .transjakarta-age-pie-chart {
  margin-top: 2rem;
}

.transjakarta-note {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

.transjakarta-container > p.transjakarta-photosource {
  font-size: 10px;
  position: relative;
  left: 50px;
  top: 100px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media (min-width: 1024px) {
  .transjakarta-container > * {
    grid-column: auto;
  }
}

@media (max-width: 1024px) {
  .transjakarta-container {
    display: none;
  }
}
</style>
