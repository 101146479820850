<template>
    <div data-aos="fade-right" data-aos-duration="2000" class="social-media-container">
        <h4>Sosial Media Favorit Gen Z<br />
                Dan Milenial
          </h4>
        <div class="social-media-wrapper">
            <div class="social-media-graph">
                <SocialMediaFavGenZPieChart
                  :marketplace="4.4"
                  :faceboook="6.3"
                  :whatsapp="16.4"
                  :youtube="4.3"
                  :tiktok="42.7"
                  :instagram="24.5"
                  />
            </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="2000" class="social-media-image">
              <img src="../../assets/annual-report-2024/bg_footprint_second.png" alt="social-media-person">
        </div>
    </div>
</template>

<script>
import SocialMediaFavGenZPieChart from './SocialMediaFavGenZPieChart.vue';

export default {
    name: "social-media-container",
    components: { SocialMediaFavGenZPieChart }
}
</script>

<style>
.social-media-container {
  background-color: #7E8AE9;
  color: #ffffff;
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.social-media-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-column-start: 1;
  grid-column-end: 2;
}

.social-media-image {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  align-items: flex-end;
  justify-content: flex-end;
}

.social-media-container h4 {
  font-family: "RMConnect";
  padding-top: 3rem;
  padding-left: 3rem;
  text-transform: uppercase;
  font-size: 2.1rem;
  letter-spacing: 2px;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .social-media-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px) {
  .social-media-image img {
    height: 650px;
  }

  .social-media-container h4 {
    font-family: "RMConnect";
    padding-top: 3rem;
    padding-left: 1rem;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1271px) {
  .social-media-container h4 {
    font-size: 2.8rem;
  }

  .social-media-image img {
    height: 850px;
  }
}
</style>
