<template>
  <ApexChart
    height="auto"
    width="100%"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "trend-consumen-second-chart",
  data: function () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 24,
            fontFamily: "Inter, Arial",
            borderRadiusApplication: "end",
            barHeight: 112,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          offsetX: 80,
          formatter: (value) => `${value}%`,
        },
        fill: {
          colors: [
            ({ value, seriesIndex, w, dataPointIndex }) =>
              ["#04E3F4", "#6AF4FF"][dataPointIndex],
          ],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        labels: {
          style: {
            colors: ["#FFFFFF"],
          },
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          categories: ["Restaurant", "Coffee Shop"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            minWidth: 120,
            maxWidth: 200,
            style: {
              fontSize: "24px",
              fontWeight: 600,
              fontFamily: "Inter, Arial",
              colors: ["#FFFFFF"],
            },
          },
        },
        responsive: [
          {
            breakpoint: 1512,
            options: {
              chart: {
                width: 448.5,
              },
            },
          },
          {
            breakpoint: 1512,
            options: {
              chart: {
                width: 500,
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: "center",
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetX: 0,
                formatter: (value) => `${value}%`,
              },
              yaxis: {
                labels: {
                  minWidth: 100,
                  maxWidth: 200,
                  style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Inter, Arial",
                    colors: ["#FFFFFF"],
                  },
                },
              },
            },
          },
        ],
      },
      series: [
        {
          data: [72.28, 22.72],
        },
      ],
    };
  },
};
</script>
