<template>
    <div class="mrt-gender-chart">
      <div class="label">Pria</div>
      <div class="bar">
        <div
          class="bar-area male"
          v-bind:style="{ width: percentageOf(priaPercentage), scale: '1 1' }"
        />
        {{ priaPercentage }}%
      </div>
      <div class="label">Wanita</div>
      <div class="bar">
        <div
          class="bar-area female"
          v-bind:style="{ width: percentageOf(wanitaPercentage), scale: '1 1' }"
        />
        {{ wanitaPercentage }}%
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "mrt-gender-chart",
    props: ["priaPercentage", "wanitaPercentage"],
    methods: {
      percentageOf (value) {
        return `${value || 0}%`;
      },
    },
  };
  </script>

  <style>
  .mrt-gender-chart {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }

  .mrt-gender-chart .male {
    --bar-color: #00e3f4;
  }

  .mrt-gender-chart .female {
    --bar-color: #f3aeff;
  }

  .mrt-gender-chart .label {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
  }

  .mrt-gender-chart .bar {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    align-items: center;
  }

  .mrt-gender-chart .bar-area {
    background-color: var(--bar-color);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 1.5rem;
    width: 0%;
    scale: 0 1;
    transform-origin: left;
    animation: pria-wanita-bar-animation 0.5s ease-in-out;
  }

  @keyframes pria-wanita-bar-animation {
    0% {
      opacity: 0;
      scale: 0 1;
    }
    100% {
      opacity: 1;
      scale: 1 1;
    }
  }
  </style>
