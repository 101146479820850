<template>
  <div class="marketing-container">
    <div class="grid">
      <h4 data-aos="fade-down" data-aos-duration="3000" class="grid-content">
        Cara Efektif Pemasaran <br />
        Bagi Generasi Z dan Milenial
      </h4>
      <p data-aos="fade-down" data-aos-duration="3000" class="grid-content">
        Meski kehadiran media sosial juga memiliki peran penting untuk menarik
        perhatian, <br />
        tapi nyatanya pemasaran dari mulut ke mulut tetap jadi yang paling
        efektif untuk dilakukan.
      </p>
      <div data-aos="fade-right" data-aos-duration="3000" class="grid-breakout">
        <MarketingTechnicBarChart />
      </div>
      <div data-aos="fade-left" data-aos-duration="3000" class="grid-full marketing-description">
        <div class="marketing-description-background">
          <p>
          Fitur survey yang kami hadirkan membantu merchant dan lokasi
          HIGOspot<br />
          untuk membuat survei. Salah satu pertanyaan yang paling sering
          mereka<br />
          tanyakan adalah <span>“Darimana Kamu Mengetahui Tempat Kami?”</span>. Hasilnya<br />
          banyak dari mereka yang menjawab <span>Rekomendasi Teman</span>.
          </p>
          <p>
            <span
              >Mereka akan lebih percaya rekomendasi teman atau kerabat,
              ketimbang</span
            ><br />
            <span>hanya iklan yang mereka lihat</span>. Tak heran karena
            berdasarkan hasil survei, <br />
              <a
              href="https://www.nielsen.com/insights/2021/beyond-martech-building-trust-with-consumers-and-engaging-where-sentiment-is-high/"
              >
              92% konsumen percaya rekomendasi teman dan keluarga<sup>10</sup>.</a
              >
            Selain itu,<br />
            <a href="https://www.semrush.com/blog/word-of-mouth-stats/">
              78% orang akan menceritakan pengalaman terbaru</a
            >
            mereka kepada<br />
            orang yang dikenal setidaknya seminggu sekali.
          </p>
        </div>
      </div>
    </div>
    <p class="marketing-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import MarketingTechnicBarChart from "./MarketingTechnicBarChart.vue";

export default {
  name: "marketing-container",
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
  components: { MarketingTechnicBarChart },
};
</script>

<style>
.marketing-container {
  padding-top: 3rem;
  padding-bottom: 4rem;
  background-image: url(~@/assets/annual-report-2024/yearly_bg_millenials.jpg);
  background-size: cover;
  color: #ffffff;
}

.marketing-container .grid {
  row-gap: 2rem;
  grid-template-rows: auto auto 10rem auto;
}

.marketing-container h4 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-size: 3rem;
  letter-spacing: 4px;
}

.marketing-description {
  padding-top: 3rem;
  padding-left: 40rem;
  padding-bottom: 4rem;
}

.marketing-description-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5400D4;
  border-bottom-left-radius: 45px;
  padding: 30px;
}

.grid .grid-content {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.marketing-description p:nth-child(1){
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.marketing-description p + p {
  margin-top: 2rem;
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.marketing-description-background p span {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

.marketing-description-background p a {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

.marketing-description a {
  color: #ffffff;
  font-weight: 600;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.marketing-photosource {
  font-size: 10px;
  position: relative;
  left: 30px;
}

@media only screen and (max-width: 1024px) {
  .marketing-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .marketing-description-background {
    padding: 18px;
  }

  .marketing-description-background p {
    font-size: 0.9rem;
  }

  .marketing-description {
    padding-top: 3rem;
    padding-left: 26rem;
    padding-bottom: 4rem;
}
}
</style>
