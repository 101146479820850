<template>
  <div class="favorite-container">
    <div data-aos="fade-right" data-aos-duration="3000" class="favorite-title">
      <p><span>Jika melihat lebih dalam,</span><br />
        Kopi Susu Gula Aren dan Long Black<br />
        menjadi kegemaran para pria,<br />
        sedangkan varian Avocado Coffee<br />
        lebih disukai wanita.
      </p>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="favorite-wrapper">
      <div class="favorite-graph">
        <CoffeeFavBarChart />
      </div>
      <div class="favorite-desc">
        <div class="favorite-desc-content">
          <p>
            Insight ini bisa digunakan coffee shop agar bisa menyajikan kopi<br />
            yang lebih bervariasi. Sebagai contoh <span>jika coffee shop milikmu</span><br />
            <span>didominasi oleh pria, buatlah varian kopi yang punya citarasa yang</span><br />
            <span>kuat dan khas, tapi jika didominasi oleh wanita cobalah buat</span><br />
            <span>varian kopi dan es krim jadi rekomendasi menu yang perlu di-</span><br />
            <span>explore lagi.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoffeeFavBarChart from "./CoffeeFavBarChart.vue";
export default {
  name: "favorite-container",
  components: {
    CoffeeFavBarChart,
  },
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: "",
    };
  },
};
</script>

<style>
.favorite-container {
  padding-top: 3rem;
  color: #ffffff;
  min-height: calc(100svh - 80px);
  background-image: url(~@/assets/annual-report-2024/bg_coffav.jpg);
  background-size: cover;
}

.favorite-title p {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 2;
  margin-bottom: 10px;
  padding-left: 4rem;
}

.favorite-title p span {
  font-weight: 200;
  font-family: "Inter", sans-serif;
}

.favorite-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.favorite-wrapper > :nth-child(1) {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.favorite-wrapper > :nth-child(2) {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  align-items: start;
  align-self: center;
  justify-content: center;
  text-align: start;
  padding-left: 6rem;
  padding-right: 4rem;
}

.favorite-desc {
  background-color: #5400D4;
  border-bottom-left-radius: 45px;
  place-self: end;
  padding: 20px 30px 30px 40px;
}

.favorite-desc-content p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.favorite-desc-content p span {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

@media only screen and (max-width: 1024px) {
  .favorite-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
  .favorite-desc-content p {
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .favorite-wrapper > :nth-child(2) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 1271px) {
  .favorite-wrapper > :nth-child(2) {
    padding-left: 2rem;
  }
}
</style>
