<template>
  <div class="location-sample-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="location-description">
      <div class="location-description-first">
        <p>Setelah pandemi melanda, industri F&B dan transportasi di<br />
        Indonesia nyatanya mulai kembali pulih. Optimisme ini sejalan<br />
        dengan data dari Badan Pusat Statistik yang mencatat<br />
        pertumbuhan industri F&B mencapai
        <a href="https://en.antaranews.com/news/278436/food-beverage-industry-predicted-to-grow-7-ministry">sebesar 4.9 persen<sup>1</sup></a> dari<br/>
        tahun sebelumnya.</p>
      </div>
      <div class="location-description-second">
        <p>Sedangkan untuk pengguna transportasi publik seperti</p>
        <p>Transjakarta, berhasil <a href="https://ppid.transjakarta.co.id/pusat-data/statistika">mencapai 1 juta penumpang<sup>2</sup></a> per hari</p>
        <p>pada <a href="https://news.detik.com/berita/d-6773611/pertama-sejak-pandemi-penumpang-transjakarta-capai-1-juta-per-hari">Selasa, 13 Juni 2023<sup>3</sup></a>
          untuk pertama kalinya setelah
        </p>
        <p>pandemi.</p>
      </div>
      <div class="location-description-third">
        <p>Berbeda dengan industri F&B dan transportasi, penggunaan<br />
          internet selama pandemi semakin meningkat.<br />

          <br />

          Tercatat <a href="https://survei.apjii.or.id/survei/">pengguna internet di Indonesia<sup>4</sup></a><br />
          <br />
        </p>
        <div class="internet-percentage-wrapper">
          <div class="internet-percentage-item">
            <p>2019-2020</p>
            <span>73.70%</span>
          </div>
          <div class="internet-percentage-item">
            <p>2021-2022</p>
            <span>77.02%</span>
          </div>
          <div class="internet-percentage-item-diff">
            <p>2023</p>
            <span>78.19%</span>
          </div>
        </div>
      </div>
      <div class="location-description-fourth">
        <p>Sebagai generasi yang paling mendominasi di Indonesia, </p>
        <p>kehadiran milenial dan Gen Z menjadi generasi yang memiliki </p>
        <p>pengaruh besar terhadap tren konsumsi dan
          <a href="https://www.mckinsey.com/industries/consumer-packaged-goods/our-insights/meet-generation-z-shaping-the-future-of-shopping">
          membentuk masa depan belanja<sup>5</sup>.</a> Sehingga memahami kebiasaan Gen Z dan
        </p>
        <p>milenial dalam menggunakan internet, memilih tempat</p>
        <p>nongkrong hingga menggunakan transportasi publik memiliki</p>
        <p>peran yang penting.</p>
      </div>
    </div>
    <div data-aos="fade-up" data-aos-duration="1800" class="location-brief">
      <p class="number">539</p>
      <p class="number-subject">Lokasi</p>
      <p class="location-title">Kami jadikan sampel penelitian dari<br />
          berbagai kategori
      </p>
      <p class="location-content">mulai dari coffee shop, resto, dan transportasi publik untuk<br />
        memahami generasi z dan milenial.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'location-container',
  data: function () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      lang: '',
    };
  },
};
</script>

<style>
.location-sample-container {
  background-color: #233ae0;
  color: #ffffff;
  min-height: calc(100svh - 80px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.location-description {
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  padding-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}

.location-description p,
.location-brief .number-subject,
.location-brief .location-title,
.location-brief .location-content{
  font-family: "Inter", sans-serif;
  line-height: 1.4;
}

.location-brief .number {
  font-family: "RMConnect";
}

.location-description a {
  color: #ffffff;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.location-description sup {
  font-size: 8px;
}

.location-description-second,
.location-description-third,
.location-description-fourth {
  margin-top: 25px;
}

.location-description-third span {
  margin-left: 25px;
}

.location-description-third span:nth-child(3) {
  margin-left: 68px;
}

.location-brief {
  display: flex;
  flex-direction: column;
  grid-column-start: 3;
  grid-column-end: 6;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
  background: url("~@/assets/annual-report-2024/yearly_bg_location.jpg");
}

.number {
  font-size: 5rem;
  font-weight: bold;
  line-height: 0.7;
}

.number-subject {
  font-size: 2.5rem;
  margin-top: 15px;
}

.location-brief .title {
  font-size: 3rem;
  font-weight: bold;
}

.location-brief .location-title, .location-subtitle {
  font-size: 1.6rem;
  font-weight: 900;
  text-align: center;
  margin-top: 20px;
}

.location-brief .location-content{
  font-size: 1.2rem;
  text-align: center;
  margin-top: 20px;
}

.location-subcontent  {
  font-size: 1.2rem;
  text-align: center;
}

.internet-percentage-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.internet-percentage-item {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;
}

.internet-percentage-item-diff {
  display: flex;
  flex-direction: row;
  gap: 62px;
}

.internet-percentage-item span,
.internet-percentage-item-diff span {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

@media (max-width: 1024px) {
  .location-sample-container {
    display: none;
  }
}
</style>
