<template>
    <div class="watertransport-container">
        <div data-aos="fade-right" data-aos-duration="3000">
            <p>
                <span>Meski generasi Z dan milenial begitu akrab dengan</span><br />
                <span>transportasi online, tapi mereka juga tetap menggunakan</span><br />
                <span> transportasi publik.</span> Terbukti dari hasil survei yang<br />
                menunjukkan bahwa generasi Z dan milenial menjadi pengguna<br />
                yang paling mendominasi di transjakarta, MRT, dan LRT.
            </p>
            <p class="dominantransport-photosource">Photo Source: Unsplash</p>
        </div>
        <div data-aos="fade-left" data-aos-duration="3000">
            <p>
                Tak hanya transportasi yang sering digunakan untuk <br />
                beraktivitas dalam keseharian, kami juga mengumpulkan data<br />
                untuk mengetahui <i>peak season</i> dari mode transportasi laut<br />
                seperti ASDP dan Pelindo.<br />

                <br />

                Hasilnya keduanya memiliki pengunjung yang <span>didominasi</span><br />
                <span>oleh pria. Sehingga menampilkan materi iklan yang</span><br />
                <span>berhubungan dengan otomotif, elektronik, atau gaming bisa</span><br />
                <span>jadi pilihan.</span>
            </p>
            <p class="watertransport-photosource">Photo Source: www.kabarbumn.com / PT ASDP Indonesia Ferry (Persero) (Dok. ASDP)</p>
        </div>
    </div>
</template>

<script>
    export default {
    name: 'hangout-container',
    data: function () {
        return {
        windowWidth: 0,
        windowHeight: 0,
        lang: '',
        };
    },
    };
</script>

<style>
.watertransport-container {
    background-color: #0000EA;
    color: #ffffff;
    min-height: calc(100svh - 80px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.watertransport-container > :nth-child(1) {
  background-image: url(~@/assets/annual-report-2024/bgtext_genz_online_transport.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  align-items: center;
  justify-content: center;
}

.watertransport-container > :nth-child(2) {
  background-image: url(~@/assets/annual-report-2024/bgtext_water_transport.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 4;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.watertransport-container p {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    line-height: 1.4;
}

.watertransport-container p span {
    font-weight: 900;
}

.watertransport-photosource {
  font-size: 10px !important;
  position: relative;
  left: 50px;
  top: 250px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

.dominantransport-photosource {
  font-size: 10px !important;
  position: relative;
  left: 50px;
  top: 300px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media only screen and (max-width: 1024px) {
  .watertransport-container {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1270px)  {
    .watertransport-container p {
        font-size: 1rem;
    }

    .watertransport-container > :nth-child(2){
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
</style>
