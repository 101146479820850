<template>
    <ApexChart
      width="100%"
      height="600"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </template>

  <script>
  export default {
    name: "pelindo-gender-bar-chart",
    data: function () {
      return {
        chartOptions: {
          colors: ["#F2ADFF", "#00E2F4"],
          chart: {
            background: "transparent",
            foreColor: "#fff",
            type: "bar",
            height: 430,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 8,
              borderRadiusApplication: "end",
              columnWidth: 30,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          legend: {
            show: false,
            markers: {
              width: 12,
              height: 12,
              radius: 6,
            },
            itemMargin: {
              vertical: 30,
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -30,
            formatter: (value) => `${value}%`,
          },
          markers: {
            colors: ["#fff"],
          },
          grid: {
            borderColor: "#ffffff50",
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          tooltip: {
            enabled: false,
          },
          stroke: {
            width: 14,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct"],
            labels: {
              style: {
                colors: ["#FFFFFF"],
                fontSize: 16,
              },
              offsetY: 5,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
        },
        series: [
          {
            name: "Female",
            data: [14.29, 26.98, 16.67, 9.43, 14.25, 25.89, 20.03, 23.51, 25.44],
          },
          {
            name: "Male",
            data: [85.71, 73.02, 83.33, 90.57, 85.75, 74.11, 79.97, 76.49, 74.56]
          }
        ],
      };
    },
  };
  </script>
