<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>
        Pola Analisis pengunjung Coffee Shop Berdasarkan Durasi Beroperasinya
      </h2>
    </div>
    <div class="analysis">
      <div data-aos="fade-right" data-aos-duration="1800" class="tables">
        <div class="table">
          <div class="headers">
            <div class="data">
              <h2>New Coffee Shop</h2>
              <p>Beroperasi Kurang dari 12 bulan</p>
            </div>
            <div class="data">
              <img
                src="../../assets/annual-report-2024/section24-2.svg"
                alt="icon"
              />
              <h2>New Coffee Shop</h2>
              <p>(Satu Cabang)</p>
            </div>
            <div class="data">
              <img
                src="../../assets/annual-report-2024/section24-2.svg"
                alt="icon"
              />
              <h2>Chain Coffee Shop</h2>
              <p>(Lebih Dari Satu Cabang)</p>
            </div>
          </div>
          <div class="row">
            <div class="data">Rata-rata pengunjung baru yang datang</div>
            <div class="data"><strong>120</strong> pengunjung</div>
            <div class="data"><strong>60</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">Rata-rata pengunjung yang kembali</div>
            <div class="data"><strong>15</strong> pengunjung</div>
            <div class="data"><strong>1130</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">
              Rata-rata pengunjung datang kembali dalam sebulan
            </div>
            <div class="data"><strong>3</strong> pengunjung</div>
            <div class="data"><strong>3</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">
              Rata-rata pengunjung menghabiskan waktu selama
            </div>
            <div class="data"><strong>2</strong> jam</div>
            <div class="data"><strong>2</strong> jam</div>
          </div>
          <div class="row">
            <div class="data">Jarak waktu pengunjung datang kembali</div>
            <div class="data"><strong>3-11</strong> hari</div>
            <div class="data"><strong>4-11</strong> hari</div>
          </div>
        </div>
        <div class="table">
          <div class="headers">
            <div class="data">
              <h2>Established Coffee Shop</h2>
              <p>Beroperasi Lebih dari 12 bulan</p>
            </div>
            <div class="data"></div>
            <div class="data"></div>
          </div>
          <div class="row">
            <div class="data">Rata-rata pengunjung baru yang datang</div>
            <div class="data"><strong>107</strong> pengunjung</div>
            <div class="data"><strong>191</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">Rata-rata pengunjung yang kembali</div>
            <div class="data"><strong>458</strong> pengunjung</div>
            <div class="data"><strong>1576</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">
              Rata-rata pengunjung datang kembali dalam sebulan
            </div>
            <div class="data"><strong>3</strong> pengunjung</div>
            <div class="data"><strong>3</strong> pengunjung</div>
          </div>
          <div class="row">
            <div class="data">
              Rata-rata pengunjung menghabiskan waktu selama
            </div>
            <div class="data"><strong>3</strong> jam</div>
            <div class="data"><strong>4</strong> jam</div>
          </div>
          <div class="row">
            <div class="data">Jarak waktu pengunjung datang kembali</div>
            <div class="data"><strong>3-7</strong> hari</div>
            <div class="data"><strong>4-11</strong> hari</div>
          </div>
        </div>
      </div>
      <div data-aos="fade-left" data-aos-duration="1800" class="summary">
        <p>
          Kami mengamati terdapat pola yang menarik dari pengunjung yang datang
          berdasarkan durasi berdirinya Coffee Shop. Hasilnya ⁠kepercayaan
          kepercayaan Gen Z dan Millenials terhadap
          <i>Chain Coffee Shop</i> jauh lebih tinggi dari
          <i>Independent Coffee Shop</i>, dilihat dari
          <strong>jumlah pengunjung yang kembali melebihi 60%</strong>, dan juga
          mempunyai kepercayaan lebih terhadap
          <strong><i>Established Coffee Shop.</i></strong>
        </p>
        <p>
          Pengunjung memerlukan waktu hampir 12 bulan untuk
          <strong>merasa nyaman</strong> di coffee shop, agar dapat menghabiskan
          <strong>waktu lebih lama</strong> di lokasi tersebut
          <strong>(lebih dari 2 jam)</strong> dan lebih sering kembali ke coffee
          shop tersebut <i>(1.5 hari lebih cepat dari New Coffee Shop)</i>
        </p>
        <p>
          ⁠⁠Independent Coffee shop yang baru memiliki
          <strong>daya tarik tersendiri</strong> dari Chain Coffee Shop yang
          baru, dimana lebih dari <strong>50% pengunjung baru</strong> lebih
          menyukai untuk datang ke <i>Independent Coffee Shop</i>.
        </p>
      </div>
    </div>
    <p class="analysis-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
export default {
  name: "analysis-pattern",
};
</script>

<style scoped>
.section-container {
  background-image: url("../../assets/annual-report-2024/section24-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 1080px;
  padding-top: 6rem;
  padding-bottom: 6rem;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.section-container .header {
  color: white;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
  max-width: 884px;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  gap: 4.313rem;
}
.section-container .analysis .tables .table {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  color: white;
}
.section-container .analysis .tables .table .headers {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: end;
}
.section-container .analysis .tables .table .row {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: end;
}
.section-container .analysis .tables .table .row:nth-child(odd) {
  background-color: #253be2;
}
.section-container .analysis .tables .table .row:nth-child(even) {
  background-color: #0014ae;
}
.section-container .analysis .tables .table .row:nth-child(2) {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.section-container .analysis .tables .table .row:last-child {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.section-container .analysis .tables .table .row .data:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.section-container .analysis .tables .table .row .data {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 434px;
  padding: 10px 0px;
}

.section-container .analysis .tables .table .row .data:first-child {
  flex-direction: column;
  align-items: end;
  width: 500px;
}

.section-container .analysis .tables .table .headers .data:first-child {
  flex-direction: column;
  align-items: end;
  width: 500px;
}
.section-container .analysis .tables .table .headers .data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 434px;
  padding-bottom: 20px;
  padding-top: 40px;
}
.section-container .analysis .tables .table .headers .data:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.section-container .analysis .tables .table .headers .data img {
  padding-bottom: 8px;
}

.section-container .analysis .tables .table:last-child .headers .data {
  padding-top: 100px;
}
.section-container .analysis .tables .table:last-child {
  margin-top: -50px;
}
.section-container .analysis .summary {
  color: white;
  background-color: #5400d4;
  padding: 3.438rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 557.81px;
  max-height: 770px;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}
.section-container .analysis .summary p {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 30px;
}

.analysis-photosource {
  color: #ffffff;
  font-size: 10px !important;
  position: relative;
  top: -95px;
  left: 18px;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 1080px;
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    gap: 1rem;
  }
  .section-container .analysis {
    justify-content: center;
    align-items: center;
  }
  .section-container .analysis .table {
    width: 750px;
  }
  .section-container .analysis .summary {
    padding: 4rem 3rem;
  }
  .section-container .analysis .summary p {
    font-size: 12px;
  }
  .section-container .analysis .tables .table .headers {
    font-size: 14px;
  }
  .section-container .analysis .tables .table .row {
    font-size: 10px;
  }

  .section-container .analysis {
    gap: 1rem;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .section-container {
    height: auto;
    padding-right: 2rem;
  }

  .section-container .analysis {
    flex-direction: column;
  }

  .section-container .analysis .summary {
    background-color: transparent;
    /* border-radius: 2rem; */
    max-width: unset;
    gap: 10px;
    max-width: 750px;
    padding: 4rem 0rem;
  }

  .section-container .analysis .summary p {
    line-height: 1.5;
  }

  .section-container .analysis .summary {
    color: white;
    background-color: #5400d4;
    padding: 3.438rem;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 80px;
  }
}
</style>
