<template>
  <ApexChart
    width="100%"
    height="500"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "favorite-food-order-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#F2ADFF", "#00E2F4"],
        chart: {
          background: "transparent",
          fontFamily: "Inter, Arial",
          foreColor: "#fff",
          type: "bar",
          height: 200,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 25,
            borderRadiusApplication: "end",
            columnWidth: 56,
            fontFamily: "Inter, Arial",
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        legend: {
          markers: {
            width: 12,
            height: 12,
            radius: 6
          },
          fontFamily: "Inter, Arial",
          itemMargin: {
            vertical: 30
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          formatter: (value) => `${value}%`
        },
        fill: {
          colors: ["#F2ADFF", "#00E2F4"]
        },
        markers: {
          colors: ["#fff"]
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 6,
          colors: ["transparent"]
        },
        xaxis: {
          tickAmount: 3,
          categories: ["GoFood", "GrabFood", "ShopeeFood"],
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: 16
            },
            offsetY: 5
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        }
      },
      series: [
        {
          name: "Perempuan",
          data: [29.17, 57.14, 66.67]
        },
        {
          name: "Laki - laki",
          data: [70.83, 42.86, 33.33]
        }
      ]
    };
  }
};
</script>
