<template>
  <div class="social-media-pie-chart">
    <ApexChart
      type="pie"
      width="600px"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
export default {
  name: "social-media-pie-chart",
  props: {
    marketplace: Number,
    faceboook: Number,
    whatsapp: Number,
    youtube: Number,
    tiktok: Number,
    instagram: Number,
  },
  data: function () {
    return {
      chartOptions: {
        labels: [
          "Market Place",
          "Facebook",
          "Whatsapp",
          "Youtube",
          "Tiktok",
          "Instagram",
        ],
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "14px",
          offsetX: -40,
          offsetY: -70,
          position: "right",
          horizontalAlign: "center",
          width: 200,
          height: 290,
          markers: {
            width: 18,
            height: 18,
            radius: 9,
            offsetX: -5,
            offsetY: 3,
            fillColors: [
              "#57E1DD",
              "#FFABF5",
              "#84FFAF",
              "#0866FF",
              "#FFDEC5",
              "#FF9EA7",
            ],
          },
          labels: {
            colors: [
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
            ],
          },
          formatter: function (label, opts) {
            const percentage = opts.w.config.series[opts.seriesIndex];
            return `<div>${label}</div>
                              <div class="piechart-legend-label">${percentage.toFixed(
                                2
                              )}%
                              </div>
                              `;
          },
        },
        fill: {
          colors: [
            "#57E1DD",
            "#FFABF5",
            "#84FFAF",
            "#0866FF",
            "#FFDEC5",
            "#FF9EA7",
          ],
        },
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      series: [
        this.marketplace,
        this.faceboook,
        this.whatsapp,
        this.youtube,
        this.tiktok,
        this.instagram,
      ],
    };
  },
};
</script>

<style>
.social-media-pie-chart .apexcharts-legend-series {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.social-media-pie-chart .apexcharts-legend.apx-legend-position-right {
  margin-top: 100px;
}

.social-media-pie-chart .piechart-legend-label {
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
}

.social-media-pie-chart .apexcharts-legend-text *:nth-child(1) {
  font-size: 12px;
  font-weight: normal;
  margin-top: 3px;
  opacity: 0.8;
}

.social-media-pie-chart .apexcharts-canvas svg {
  overflow: visible;
}

.social-media-pie-chart .apexcharts-canvas svg foreignObject {
  overflow: visible;
}
</style>
