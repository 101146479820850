<template>
  <ApexChart
    width="100%"
    height="100%"
    type="bar"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "internet-by-area-bar-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#5086F7", "#FFA49D", "#FFCC32", "#74B979", "#F38837"],
        chart: {
          background: "transparent",
          fontFamily: "Inter, Arial",
          foreColor: "#fff",
          type: "bar",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 12,
            borderRadiusApplication: "end",
            columnWidth: 40,
            columnWidthOffset: 20,
            fontFamily: "Inter, Arial",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          offsetY: 10,
          markers: {
            width: 12,
            height: 12,
            radius: 6,
          },
          fontFamily: "Inter, Arial",
          itemMargin: {
            vertical: 10,
            horizontal: 10,
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          formatter: (value) => `${value.toFixed(2)}%`,
        },
        fill: {
          colors: ["#5086F7", "#FFA49D", "#FFCC32", "#74B979", "#F38837"],
        },
        markers: {
          colors: ["#fff"],
        },
        grid: {
          borderColor: "#ffffff50",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          width: 6,
          colors: ["transparent"],
        },
        xaxis: {
          tickAmount: 3,
          categories: ["5Mbps", "10Mbps", "20Mbps", "50Mbps"],
          labels: {
            style: {
              colors: ["#FFFFFF"],
              fontSize: 16,
            },
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: "Jakarta Barat",
          data: [0.0, 56.0, 32.0, 12.0],
        },
        {
          name: "Jakarta Pusat",
          data: [0.0, 67.86, 14.29, 17.86],
        },
        {
          name: "Jakarta Selatan",
          data: [4.0, 40.59, 20.79, 37.64],
        },
        {
          name: "Jakarta Timur",
          data: [0.0, 57.69, 15.0, 26.92],
        },
        {
          name: "Jakarta Utara",
          data: [44.0, 41.86, 16.28, 16.28],
        },
      ],
    };
  },
};
</script>
