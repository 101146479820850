<template>
  <div class="section-container">
    <div data-aos="fade-down" data-aos-duration="1500" class="header">
      <h2>Penyedia Layanan Internet yang Sering Digunakan</h2>
      <p>
        Kecepatan, stabilitas koneksi, paket harga, dan kualitas layanan jadi
        faktor penting dalam pemilihan penyedia layanan internet. Di Indonesia
        sendiri ada beberapa penyedia layanan internet yang sering digunakan.
      </p>
    </div>
    <div class="analysis">
      <div data-aos="fade-right" data-aos-duration="1800" class="apex">
        <p>Penyedia Layanan Internet di Indonesia</p>
        <div class="bubble">
          <div class="main">
            <h2>59.57%</h2>
            <span>Indihome</span>
          </div>
          <div class="secondaries">
            <div class="one">
              <div class="circle"></div>
              <div class="text">
                <h2>7.60%</h2>
                <span>Oxygen</span>
              </div>
            </div>
            <div class="two">
              <div class="circle"></div>
              <div class="text">
                <h2>8.51%</h2>
                <span>My Republic</span>
              </div>
            </div>
            <div class="three">
              <div class="circle"></div>
              <div class="text">
                <h2>8.51%</h2>
                <span>IM3</span>
              </div>
            </div>
            <div class="four">
              <h2>15.81%</h2>
              <span>Biznet</span>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-left" data-aos-duration="1800" class="apex">
        <p>Kecepatan Layanan Internet Indihome</p>
        <MostUsedIntenetProviderBarChart />
      </div>
    </div>
  </div>
</template>

<script>
import MostUsedIntenetProviderBarChart from "./MostUsedInternetProviderBarChart.vue";

export default {
  name: "most-used-internet-provider",
  components: {
    MostUsedIntenetProviderBarChart,
  },
};
</script>

<style scoped>
.section-container {
  background-color: #253be2;
  width: 100%;
  height: 1080px;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .header {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.section-container .header h2 {
  text-transform: uppercase;
  font-family: "RMConnect";
  font-weight: normal;
  font-size: 2.25rem;
}
.section-container .header p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  max-width: 1041px;
  color: white;
}

.section-container .analysis {
  display: flex;
  width: 100%;
  height: 90%;
  max-width: 1908px;
  margin: auto;
  gap: 10%;
}
.section-container .analysis .bubble {
  position: relative;
  width: 100%;
  max-width: 704px;
  display: flex;
  gap: 6px;
  max-height: 576.38px;
  height: 100%;
  font-family: "Inter", sans-serif;
}
.section-container .analysis .bubble .main {
  background-color: #04e3f4;
  color: #253be2;
  display: flex;
  height: 356.73px;
  width: 356.73px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.section-container .analysis .bubble .main h2 {
  font-size: 2rem;
  font-weight: bold;
}
.section-container .analysis .bubble .main span {
  font-size: 1.188rem;
  font-weight: 300;
}
.section-container .analysis .bubble .secondaries {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 341px;
  height: 100%;
  padding-top: 90px;
}
.section-container .analysis .bubble .secondaries .one {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
}
.section-container .analysis .bubble .secondaries .one .circle {
  width: 64px;
  height: 64px;
  background-color: #00bcca;
  border-radius: 50%;
}
.section-container .analysis .bubble .secondaries .one .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.section-container .analysis .bubble .secondaries .one .text span {
  font-weight: 300;
}
.section-container .analysis .bubble .secondaries .two {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
}
.section-container .analysis .bubble .secondaries .two .circle {
  width: 132px;
  height: 132px;
  background-color: #00bcca;
  border-radius: 50%;
}
.section-container .analysis .bubble .secondaries .two .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.section-container .analysis .bubble .secondaries .two .text span {
  font-weight: 300;
}
.section-container .analysis .bubble .secondaries .three {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  position: absolute;
  top: 55%;
  right: 30%;
}
.section-container .analysis .bubble .secondaries .three .circle {
  width: 132px;
  height: 132px;
  background-color: #00bcca;
  border-radius: 50%;
}
.section-container .analysis .bubble .secondaries .three .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.section-container .analysis .bubble .secondaries .three .text span {
  font-weight: 300;
}
.section-container .analysis .bubble .secondaries .four {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;
  position: absolute;
  left: 10%;
  bottom: 0%;
  width: 206px;
  height: 206px;
  background-color: #00bcca;
  border-radius: 50%;
}
.section-container .analysis .bubble .secondaries span {
  font-weight: 300;
}
.section-container .analysis .apex:first-child {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .analysis .apex:last-child {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 30%;
}

.section-container .analysis .apex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.section-container .analysis .apex p {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  max-width: 790px;
  color: white;
}

@media (max-width: 1024px) {
  .section-container {
    display: none;
  }
}

@media screen and (max-width: 1512px) {
  .section-container {
    height: 900px;
  }
  .section-container .analysis .apex p {
    font-size: 1rem;
    max-width: 500px;
  }
  .section-container .analysis .apex:last-child {
    width: 60%;
  }
  .section-container .analysis {
    gap: 10px;
  }
  .section-container .analysis .bubble .main {
    height: 250px;
    width: 250px;
  }
  .section-container .analysis .bubble .secondaries {
    padding-top: 20px;
  }
  .section-container .analysis .bubble .secondaries .four {
    left: 5%;
    /*bottom: 4%;*/
    width: 160px;
    height: 160px;
  }

  .section-container .analysis .apex:first-child {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
}
</style>
