<template>
  <section class="annual-report-hero-container">
    <AnnualReportFormModal :is-modal="true" />
    <img
      class="hero-background"
      src="~@/assets/annual-report-2024/yearly_bg_cover.jpg"
    />
    <img
      class="hero-image"
      src="~@/assets/annual-report-2024/bg_cover_millenials.png"
    />
    <div class="circle" />
    <h1>
      HIGO DIGITAL <br />
      MANUAL 2024<br />
    </h1>
    <p class="description">
      Inovasi Digital Membentuk Trend Kebiasaan <br />
      Gen Z & Milenial di Indonesia
    </p>
    <p class="hint">
      Agar kamu dapat merasakan pengalaman terbaik, kami sarankan untuk
      mengakses halaman ini melalui versi desktop atau download full report
    </p>
    <button class="download-report-mobile" @click="showDownloadFormModal">
      Download Full Report
    </button>
    <div class="download-wrapper">
      <button class="download-report-desktop" @click="showDownloadFormModal">
        Download Full Report
      </button>
    </div>
  </section>
</template>

<script>
import AnnualReportFormModal from "./AnnualReportFormModal.vue";

export default {
  name: "AnnuelHero",
  components: {
    AnnualReportFormModal,
  },
  methods: {
    showDownloadFormModal() {
      this.$modal.show("annual-report-form-modal");
    },
  },
};
</script>

<style>
.annual-report-hero-container {
  --theme-color: #17e3f4;
  --background-color: #243be1;

  background-color: var(--background-color);
  color: #ffffff;
  overflow: initial;
  display: grid;
  grid-template-columns: [full-start] 2rem [content-start] 1fr [content-end] 2rem [full-end];
  grid-template-rows:
    [background-start] 6rem
    [cover-start] auto [cover-end] 2rem
    [title-start] auto [title-end] 1rem
    [description-start] auto [description-end] 2rem
    [background-end] 2rem
    [content-start] auto [content-end] 2rem
    [button-start] auto [button-end] 2rem;
}

.annual-report-hero-container > * {
  grid-column: full;
}

.annual-report-hero-container > h1,
.annual-report-hero-container > p,
.annual-report-hero-container > a,
.annual-report-hero-container > button {
  grid-column: content;
}

.annual-report-hero-container > .hero-background {
  grid-row: background;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.annual-report-hero-container > .hero-image {
  grid-row: cover;
  object-fit: contain;
  max-width: 100%;
  max-height: 50vh;
  justify-self: center;
  align-self: center;
  padding-inline: 4rem;
}

.annual-report-hero-container > h1,
.annual-report-hero-container > p.description,
.annual-report-hero-container > p.hint,
.annual-report-hero-container > button.download-report-mobile {
  font-family: "Inter", sans-serif;
  justify-self: center;
  align-self: center;
  text-align: center;
}

.annual-report-hero-container > h1 {
  font-family: "RMConnect";
  font-weight: 500;
  grid-row: title;
  font-size: clamp(1rem, 6vw, 2rem);
}

.annual-report-hero-container > p.description {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  grid-row: description;
  font-size: clamp(0.9rem, 4vw, 1.2rem);
}

.annual-report-hero-container > p.hint {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  grid-row: content;
  max-width: 300px;
}

.download-report-desktop {
  font-family: "Inter", sans-serif;
}

.annual-report-hero-container > button,
.annual-report-hero-container > .download-wrapper > button {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  background-color: var(--theme-color);
  color: var(--background-color);
  border-radius: 100px;
  border: none;
  padding: 0.7rem 3rem;
}

.annual-report-hero-container > .download-report-mobile {
  grid-row: button;
  max-width: 300px;
}

.annual-report-hero-container > .download-wrapper,
.annual-report-hero-container > .circle {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .annual-report-hero-container {
    --content-size: calc((100% - 4rem * 2) / 2);

    grid-template-columns:
      [full-start] 4rem
      [left-start] var(--content-size) [left-end] 5rem
      [right-start] calc(var(--content-size) - 5rem) [right-end] 4rem
      [full-end];
    grid-template-rows:
      [full-start] 6rem
      [content-start] 1fr
      [title-start] auto [title-end] 1rem
      [description-start] auto [description-end] 1rem
      [button-start] auto [button-end] 1fr
      [content-end] 6rem [full-end];
  }

  .annual-report-hero-container > .hero-background {
    grid-area: full;
  }

  .annual-report-hero-container > .hero-image {
    grid-area: content / left;
    max-height: 70vh;
  }

  .annual-report-hero-container > h1,
  .annual-report-hero-container > p.description {
    align-self: baseline;
    justify-self: baseline;
    text-align: left;
    z-index: 2;
  }

  .annual-report-hero-container > h1 {
    grid-area: title / right;
    font-family: "RMConnect";
    font-size: clamp(2rem, 2.9vw, 3rem);
    font-weight: 500;
    line-height: 1.4;
  }

  .annual-report-hero-container > p.description {
    grid-area: description / right;
    font-family: "Inter", sans-serif;
    font-size: clamp(0.5rem, 1.7vw, 1rem);
  }

  .annual-report-hero-container > .download-wrapper {
    display: grid;
    grid-area: button / right;
    justify-self: baseline;
    z-index: 2;
  }

  .annual-report-hero-container > .circle {
    display: block;
    grid-column-start: right;
    grid-row-start: title;
    grid-row-end: button;
    position: relative;
  }

  .annual-report-hero-container > .circle::before {
    content: "";
    position: absolute;
    background-color: var(--background-color);
    height: 200%;
    top: 50%;
    transform: translate(-14%, -50%);
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    z-index: 0;
  }

  .annual-report-hero-container > .download-report-mobile,
  .annual-report-hero-container > p.hint {
    display: none;
  }

  .download-report-desktop {
    cursor: pointer;
  }
}
</style>
