<template>
  <div class="mrt-container">
      <h4 data-aos="fade-down" data-aos-duration="3000">MRT</h4>
      <p data-aos="fade-down" data-aos-duration="3000">
        Tidak berbeda jauh dengan Transjakarta, mode transportasi MRT<br />
        memiliki kebiasaan sebagai berikut
      </p>
      <div data-aos="fade-right" data-aos-duration="3000">
      <div class="mrt-highlights">
        <div class="mrt-highlights-item">
          <img
            class="mrt-icon"
            src="@/assets/annual-report-2024/calendar_icon.png"
            alt="mrt-rush-month"
          />
          <div>
            <p>Bulan paling ramai</p>
            <p>Maret</p>
          </div>
        </div>
        <div class="mrt-highlights-item">
          <img
            src="@/assets/annual-report-2024/calendar_icon.png"
            alt="mrt-rush-day"
          />
          <div>
            <p>Hari paling ramai</p>
            <p>Selasa</p>
          </div>
        </div>
        <div class="mrt-highlights-item">
          <img
            src="@/assets/annual-report-2024/clock_icon.png"
            alt="mrt-rush-hour"
          />
          <div>
            <p>Jam paling ramai</p>
            <p>5 - 6 Sore</p>
          </div>
        </div>
      </div>
      <div class="mrt-highlights-chart">
        <div class="mrt-highlights-item">
          <img
            src="@/assets/annual-report-2024/visitor_icon.png"
            alt="mrt-visitor-gender"
          />
          <p>Gender Pengguna</p>
        </div>
        <MrtGenderBarChart :priaPercentage="58.37" :wanitaPercentage="42.45" />
      </div>
    </div>
    <div data-aos="fade-left" data-aos-duration="3000" class="mrt-highlights-chart">
      <div class="mrt-highlights-item">
        <img
          src="@/assets/annual-report-2024/visitor_icon.png"
          alt="mrt-visitor-age"
        />
        <p>Usia Pengguna</p>
      </div>
      <div class="mrt-age-pie-chart">
        <AgePieChart
          :under18="2.03"
          :between18And24="31.48"
          :between25And34="46.37"
          :between35And44="14.30"
          :between45And54="4.61"
          :between55And64="3.49"
          :over64="0.20"
        />
      </div>
      <p class="mrt-note">Lebih dari 75% penumpang MRT adalah milenial dan Gen Z</p>
    </div>
    <p class="mrt-photosource">Photo Source: Unsplash</p>
  </div>
</template>

<script>
import MrtGenderBarChart from './MrtGenderBarChart.vue';
import AgePieChart from './AgePieChart.vue';

export default {
    name: "mrt-container",
    components: { MrtGenderBarChart, AgePieChart }
};
</script>

<style>
.mrt-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-image: url(~@/assets/annual-report-2024/bg_mrt.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  padding-top: 6rem;
  padding-bottom: 8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  row-gap: 3rem;
  align-items: baseline;
}

.mrt-container > * {
  grid-column: -1 / 1;
}

.mrt-container > h4 {
  font-family: "RMConnect";
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 4px;
  grid-column: -1 / 1;
}

.mrt-container > p {
  font-family: "Inter", sans-serif;
  line-height: 1.4;
  text-transform: none;
  font-size: medium;
  font-weight: 200;
  max-width: 500px;
  margin-top: -2rem;
  grid-column: -1 / 1;
}

.mrt-container .mrt-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  row-gap: 2rem;
  margin-bottom: 3rem;
}

.mrt-container .mrt-highlights-item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 30px auto;
  align-items: center;
}

.mrt-container .mrt-highlights-item img {
  max-width: 100%;
}

.mrt-container .mrt-highlights-item p {
  font-family: "Inter", sans-serif;
  line-height: 25px;
}

.mrt-container .mrt-highlights-item p + p {
  font-weight: 900;
  letter-spacing: 1px;
}

.mrt-container .mrt-highlights-chart {
  display: grid;
  row-gap: 1.5rem;
}

.mrt-container .mrt-age-pie-chart {
  margin-top: 2rem;
}

.mrt-note {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

.mrt-container > p.mrt-photosource {
  font-size: 10px;
  position: relative;
  left: 50px;
  top: 100px;
  text-transform: none;
  letter-spacing: 0px;
  font-weight: 200;
}

@media (min-width: 1024px) {
  .mrt-container > * {
    grid-column: auto;
  }
}

@media (max-width: 1024px) {
  .mrt-container {
    display: none;
  }
}
</style>
