<template>
  <ApexChart
    class="negative-feedback-heatmap-chart"
    width="100%"
    height="auto"
    type="heatmap"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "negative-feedback-heatmap-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#FBD8D8"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            shadeIntensity: 0.5,
            enableShades: false,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                { from: 0, to: 19, color: "#F8E4E2" },
                { from: 20, to: 22, color: "#FCD9D8" },
                { from: 23, to: 25, color: "#FFB8B9" },
                { from: 26, to: 28, color: "#D85A5B" },
                { from: 29, to: 100, color: "#D83737" },
              ],
            },
          },
        },
        dataLabels: {
          formatter: function (value) {
            return `${value}%`;
          },
          style: {
            fontSize: 16,
            fontWeight: 400,
            colors: ["#000"],
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          position: "top",
          categories: ["Environment", "Price", "Drink", "Service", "Food"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          labels: {
            style: {
              fontSize: 16,
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          reversed: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          labels: {
            offsetX: -10,
            style: {
              fontSize: 16,
              fontWeight: 600,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      series: [
        {
          name: "Bandung",
          data: [17.47, 32.41, 23.76, 19.54, 23.91],
        },
        {
          name: "Bekasi",
          data: [16.95, 33.05, 22.08, 15.68, 22.46],
        },
        {
          name: "Bogor",
          data: [24.64, 30.12, 26.04, 22.62, 25.94],
        },
        {
          name: "Surabaya",
          data: [17.39, 19.57, 17.39, 13.04, 17.39],
        },
        {
          name: "Tangerang Selatan",
          data: [25.55, 38.06, 30.49, 24.11, 30.55],
        },
        {
          name: "Bali",
          data: [20.42, 28.54, 25.1, 21.56, 25.1],
        },
        {
          name: "Jakarta",
          data: [20.95, 29.91, 27.32, 22.2, 26.61],
        },
        {
          name: "Semarang",
          data: [29.51, 45.9, 37.7, 37.3, 39.34],
        },
        {
          name: "Kota Lainnya*",
          data: [20.49, 22.85, 11.75, 9.45, 11.94],
        },
      ],
    };
  },
};
</script>

<style>
.positive-feedback-heatmap-chart {
  width: 100%;
}
</style>
