<template>
  <ApexChart
    class="positive-feedback-heatmap-chart"
    width="100%"
    type="heatmap"
    :options="chartOptions"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  name: "positive-feedback-heatmap-chart",
  data: function () {
    return {
      chartOptions: {
        colors: ["#02B7C3"],
        chart: {
          background: "transparent",
          foreColor: "#fff",
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            shadeIntensity: 0.5,
            enableShades: false,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                { from: 0, to: 69, color: "#D9F9FC" },
                { from: 69, to: 78, color: "#BCF2F4" },
                { from: 79, to: 82, color: "#5ACFD8" },
                { from: 83, to: 100, color: "#02B7C3" },
              ],
            },
          },
        },
        dataLabels: {
          formatter: function (value) {
            return `${value}%`;
          },
          style: {
            fontSize: 16,
            fontWeight: 400,
            colors: ["#000"],
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          position: "top",
          categories: ["Environment", "Price", "Drink", "Service", "Food"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          labels: {
            style: {
              fontSize: 16,
              fontWeight: 200,
            },
          },
        },
        yaxis: {
          reversed: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
          labels: {
            offsetX: -10,
            style: {
              fontSize: 16,
              fontWeight: 600,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      series: [
        {
          name: "Bandung",
          data: [82.53, 67.59, 76.24, 80.46, 76.09],
        },
        {
          name: "Bekasi",
          data: [83.05, 66.95, 77.92, 84.32, 77.54],
        },
        {
          name: "Bogor",
          data: [75.36, 69.88, 73.96, 77.38, 74.06],
        },
        {
          name: "Surabaya",
          data: [82.61, 80.43, 82.61, 86.96, 82.61],
        },
        {
          name: "Tangerang Selatan",
          data: [74.45, 61.94, 69.51, 75.89, 69.45],
        },
        {
          name: "Bali",
          data: [79.58, 71.46, 74.9, 78.44, 74.9],
        },
        {
          name: "Jakarta",
          data: [79.05, 70.09, 72.68, 77.8, 73.39],
        },
        {
          name: "Semarang",
          data: [70.49, 54.1, 62.3, 62.3, 60.66],
        },
        {
          name: "Kota Lainnya*",
          data: [79.51, 77.17, 88.25, 90.55, 88.06],
        },
      ],
    };
  },
};
</script>

<style>
.positive-feedback-heatmap-chart {
  width: 100%;
}
</style>
